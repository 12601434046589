<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-21 14:59:59
 * @LastEditTime: 2022-04-28 16:16:38
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \networksecurity\src\views\northeastData\components\trend.vue
-->
<template>
  <div ref="trend" style="height: 280px"></div>
</template>

<script>
export default {
  mounted() {
    this.gettrend()
  },

  props: {
    chart: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    chart: {
      handler(val) {
        if (val.length > 0) {
          this.gettrend()
        }
      },
      immediate: true,
    },
  },

  methods: {
    gettrend() {
      let myChart = this.$echarts.getInstanceByDom(this.$refs.trend)
      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.trend)
      }

      let x_axis = this.chart.map((item) => item.x_axis)
      let y_axis = this.chart.map((item) => item.y_axis)

      let option = {
        grid: {
          left: '20%',
          width: '60%',
          top: '30%',
        },
        tooltip: {
          trigger: 'axis',
        },
        xAxis: {
          name: '时',
          type: 'category',
          data: x_axis,
          axisLabel: {
            show: true,
            color: '#fff',
          },
          nameTextStyle: {
            color: '#fff',
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          name: '次数',
          type: 'value',
          nameTextStyle: {
            color: '#fff',
          },
          axisLabel: {
            show: true,
            color: '#fff',
          },
          splitLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              // type: 'dashed',
              color: '#293A5F',
            },
          },
        },
        series: [
          {
            showSymbol: false,
            itemStyle: {
              color: '#5CE1F3',
            },
            data: y_axis,
            type: 'line',
          },
        ],
      }
      myChart.setOption(option)
    },
  },
}
</script>


<style lang="sass" scoped>
</style>