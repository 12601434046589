<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-20 15:41:26
 * @LastEditTime: 2022-04-28 11:40:53
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \networksecurity\src\views\powersupplyService\components\distribution.vue
-->
 
<template>
  <div
    class="distribution"
    style="width: 100%; height: 260px"
    ref="distribution"
  ></div>
</template>

<script>
export default {
  mounted() {
    this.getdistribution()
  },

  props: {
    gjfb: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    gjfb: {
      handler(val) {
        if (val.length > 0) {
          this.getdistribution()
        }
      },
    },
  },

  methods: {
    getdistribution() {
      let myChart = this.$echarts.getInstanceByDom(this.$refs.distribution)
      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.distribution)
      }

      let data1 = this.gjfb.map((item) => item.tip)
      let data2 = this.gjfb.map((item) => {
        let colorStops =
          item.name == 'serious'
            ? [
                {
                  offset: 0,
                  color: '#FF5D5D', // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: '#C72B2B', // 100% 处的颜色
                },
              ]
            : item.name == 'commonly'
            ? [
                {
                  offset: 0,
                  color: '#FC961A', // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: '#FD4419', // 100% 处的颜色
                },
              ]
            : [
                {
                  offset: 0,
                  color: '#71F6F7', // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: '#0387E1', // 100% 处的颜色
                },
              ]
        return {
          value: item.value,
          itemStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops,
              global: false, // 缺省为 false
            },
          },
        }
      })

      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: {
          left: '15%',
        },

        xAxis: {
          type: 'category',
          axisLabel: {
            show: true,
            color: '#fff',
          },
          data: data1,
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: true,
            color: '#fff',
          },
          splitLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              // type: 'dashed',
              color: '#293A5F',
            },
          },
          data: data2,
        },
        series: [
          {
            barMaxWidth: '48px',

            data: data2,
            type: 'bar',
          },
        ],
      }
      myChart.setOption(option)
    },
  },
}
</script>

<style>
</style>