<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-20 11:02:15
 * @LastEditTime: 2022-04-27 18:51:14
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \networksecurity\src\views\marketingData\components\dataCrosseomain.vue
-->
<template>
  <div ref="dataCrossEomain" style="width: 100%; height: 100%"></div>
</template>

<script>
export default {
  mounted() {
    this.getdataCrossdomain()
  },

  props: {
    form: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    form: {
      handler(val) {
        if (val.length > 0) {
          this.getdataCrossdomain()
        }
      },
      immediate: true,
    },
  },

  methods: {
    getdataCrossdomain() {
      const data1 = this.form.map((item) => item.city)
      let myChart = this.$echarts.getInstanceByDom(this.$refs.dataCrossEomain)
      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.dataCrossEomain)
      }

      let data2 = ['审批通过'].concat(this.form.map((item) => item.approved))
      let data3 = ['审批中'].concat(this.form.map((item) => item.approval))
      let data4 = ['审批驳回'].concat(this.form.map((item) => item.reject))
      let data5 = ['未提交'].concat(this.form.map((item) => item.uncommitted))

      let option = {
        color: ['#003366', '#006699', '#4cabce', '#e5323e'],
        dataset: {
          source: [['type', ...data1], data2, data3, data4, data5],
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          width: '160px',
          textStyle: {
            color: '#fff',
          },
          bottom: '4%',
          itemWidth: 12,
          itemGap: 20,
        },

        yAxis: {
          type: 'category',
          data: data1.map((item) =>
            Object.assign(
              {},
              {
                value: item,
                textStyle: {
                  color: '#999',
                },
              }
            )
          ),
          axisTick: {
            show: false,
          },
        },
        grid: {
          top: '5%',
          left: '2%',
          height: '80%',
          containLabel: true,
        },
        xAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              // type: 'dashed',
              color: '#293A5F',
            },
          },
        },
        series: [
          {
            type: 'bar',
            seriesLayoutBy: 'row',
            itemStyle: {
              color: '#71F6F7',
            },
          },
          {
            type: 'bar',
            seriesLayoutBy: 'row',
            itemStyle: {
              color: '#40E16B',
            },
          },
          {
            type: 'bar',
            seriesLayoutBy: 'row',
            itemStyle: {
              color: '#FF4963',
            },
          },
          {
            type: 'bar',
            seriesLayoutBy: 'row',
            itemStyle: {
              color: '#F1B226',
            },
          },
        ],
      }
      myChart.setOption(option)
    },
  },
}
</script>

<style>
</style>