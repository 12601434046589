<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-14 10:48:04
 * @LastEditTime: 2022-06-10 10:15:32
 * @LastEditors: cyy
 * @Description: 东北能源大数据中心
 * @FilePath: \networksecurity\src\views\northeastData\index.vue
-->
<template>
  <div id="northeastData">
    <div class="left">
      <div class="bottom_list">
        <div class="bottom_item bg1">
          <div class="b_title">敏感数据分布</div>
          <pie :acquiredOption="configure" height="350px"></pie>
        </div>
      </div>
      <div class="bottom_list mt30">
        <div class="bottom_item bg1">
          <div class="b_title">异常行为分布</div>
          <distribution :distributionOption="distributionOption"></distribution>
        </div>
      </div>
    </div>
    <div class="center cp" @click="open('http://10.160.180.15:28088/Portal')">
      <div class="center_top">
        <div class="img_center_top">
          <img src="@ass/img/1.0.0/icon_gdzh_cbg.svg" alt="" />
        </div>
        <div class="f_title mb20 m-auto">安全监控情报</div>
        <div class="contain d-flex">
          <div class="tu">
            <img src="@ass/img/1.0.0/icon_dbnydsj_qbc.svg" alt="" />
          </div>
          <div class="flex-center itemlist">
            <div class="contain_item">
              <div class="item_tu">
                <img src="@ass/img/1.0.0/icon_dbnydsj_sjsj.svg" alt="" />
              </div>
              <div class="item_name">数据收集/产生</div>
            </div>
            <div class="contain_item ml128mr109">
              <div class="item_tu">
                <img src="@ass/img/1.0.0/icon_dbnydsj_sc.svg" alt="" />
              </div>
              <div class="item_name">传输</div>
            </div>
            <div class="contain_item">
              <div class="item_tu">
                <img src="@ass/img/1.0.0/icon_dbnydsj_cc.svg" alt="" />
              </div>
              <div class="item_name">存储</div>
            </div>
          </div>
        </div>
      </div>
      <div class="center_center">
        <div class="f_title mb20 m-auto">数据中台</div>
        <div class="img_center_top">
          <img src="@ass/img/1.0.0/icon_gdzh_cbg.svg" alt="" />
        </div>
        <div class="contain d-flex">
          <div class="tu mt60">
            <img src="@ass/img/1.0.0/icon_dbnydsj_sjfxc.svg" alt="" />
          </div>
          <div>
            <div class="cneter_item_list">
              <div class="item">
                <div class="tu">
                  <img src="@ass/img/1.0.0/icon_dbnydsj_sjtm.svg" alt="" />
                </div>
                <div class="name">数据脱敏</div>
              </div>
              <div class="item">
                <div class="tu">
                  <img src="@ass/img/1.0.0/icon_dbnydsj_sjjh.svg" alt="" />
                </div>
                <div class="name">
                  <p>数据交换</p>
                  <p>共享安全</p>
                </div>
              </div>
              <div class="item">
                <div class="tu">
                  <img src="@ass/img/1.0.0/icon_dbnydsj_clsj.svg" alt="" />
                </div>
                <div class="name">
                  <p>残留数据</p>
                  <p>清除</p>
                </div>
              </div>
              <div class="item">
                <div class="tu">
                  <img src="@ass/img/1.0.0/icon_dbnydsj_qzjm.svg" alt="" />
                </div>
                <div class="name">全栈加密</div>
              </div>
              <div class="item">
                <div class="tu">
                  <img src="@ass/img/1.0.0/icon_dbnydsj_zcfz.svg" alt="" />
                </div>
                <div class="name">
                  <p>资产分组</p>
                  <p>与资产指纹</p>
                </div>
              </div>
              <div class="item">
                <div class="tu">
                  <img src="@ass/img/1.0.0/icon_dbnydsj_rzsj.svg" alt="" />
                </div>
                <div class="name">日志审计</div>
              </div>
              <div class="item">
                <div class="tu">
                  <img src="@ass/img/1.0.0/icon_dbnydsj_csjm.svg" alt="" />
                </div>
                <div class="name">传输加密</div>
              </div>
              <div class="item">
                <div class="tu">
                  <img src="@ass/img/1.0.0/icon_dbnydsj_esc.svg" alt="" />
                </div>
                <div class="name">
                  <p>ESC</p>
                  <p>侧数据安全</p>
                </div>
              </div>
            </div>
            <div class="cneter_item_list" style="margin: 21px 0px 0px 557px">
              <div class="item" style="margin-right: 20px">
                <div class="tu">
                  <img src="@ass/img/1.0.0/icon_dbnydsj_ypsj.svg" alt="" />
                </div>
                <div class="name">云盘数据</div>
              </div>
              <div class="item" style="margin-right: 20px">
                <div class="tu">
                  <img src="@ass/img/1.0.0/icon_dbnydsj_cpjm.svg" alt="" />
                </div>
                <div class="name">ECS磁盘加密</div>
              </div>
              <div class="item" style="margin-right: 20px">
                <div class="tu">
                  <img src="@ass/img/1.0.0/icon_dbnydsj_rzsj.svg" alt="" />
                </div>
                <div class="name">三副本存储</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="center_bottom">
        <div class="f_title mb20 m-auto">大数据中心</div>
        <div class="img_center_top">
          <img src="@ass/img/1.0.0/icon_gdzh_cbg.svg" alt="" />
        </div>
        <div class="contain d-flex">
          <div class="tu">
            <img
              src="@ass/img/1.0.0/icon_dbnydsj_itjcc1.svg"
              style="margin-top: 70px"
              alt=""
            />
          </div>
          <div class="center_bottom_list">
            <div class="one_item">
              <div class="item">
                <div class="tu">
                  <img src="@ass/img/1.0.0/icon_dbnydsj_ztjk.svg" alt="" />
                </div>
                <div class="name" style="margin-top: 3px">状态监控</div>
              </div>
              <div class="item mt50">
                <div class="tu">
                  <img src="@ass/img/1.0.0/icon_dbnydsj_aqfw.svg" alt="" />
                </div>
                <div class="name" style="margin-top: 3px">安全服务措施</div>
              </div>
            </div>
            <div class="tow_item">
              <div class="layer">
                <div class="contain_item">
                  <div class="item_tu">
                    <img src="@ass/img/1.0.0/icon_dbnydsj_ydqbc.svg" alt="" />
                  </div>
                  <div class="item_name">云端情报层</div>
                </div>
                <div class="contain_item ml20 mr20">
                  <div class="item_tu">
                    <img src="@ass/img/1.0.0/icon_dbnydsj_sjfxzsc.svg" alt="" />
                  </div>
                  <div class="item_name">数据分析与展示层</div>
                </div>
                <div class="contain_item">
                  <div class="item_tu">
                    <img src="@ass/img/1.0.0/icon_dbnydsj_itjcc.svg" alt="" />
                  </div>
                  <div class="item_name">IT基础层</div>
                </div>
              </div>
              <div class="layer2">
                <div class="item">
                  <div class="tu">
                    <img src="@ass/img/1.0.0/icon_dbnydsj_aqsj.svg" alt="" />
                  </div>
                  <div class="name" style="margin-top: 17px">安全审计</div>
                </div>
                <div class="item">
                  <div class="tu">
                    <img src="@ass/img/1.0.0/icon_dbnydsj_yyaq.svg" alt="" />
                  </div>
                  <div class="name" style="margin-top: 17px">应用安全</div>
                </div>
                <div class="item">
                  <div class="tu">
                    <img src="@ass/img/1.0.0/icon_dbnydsj_zjaq.svg" alt="" />
                  </div>
                  <div class="name" style="margin-top: 17px">主机安全</div>
                </div>
                <div class="item">
                  <div class="tu">
                    <img src="@ass/img/1.0.0/icon_dbnydsj_wlaq.svg" alt="" />
                  </div>
                  <div class="name" style="margin-top: 17px">网络安全</div>
                </div>
              </div>
            </div>
            <div class="three_item" v-if="dsjzx.length > 0">
              <div class="flex-center">
                <div class="t_item" style="margin-right: 80px">
                  <div class="number" style="color: #99dc72">
                    <animate-number
                      from="0"
                      :to="dsjzx[0].value"
                      duration="5000"
                      easing="easeOutQuad"
                      style="display: block"
                    ></animate-number>
                  </div>
                  <div class="name">{{ dsjzx[0].tip }}</div>
                </div>
                <div class="t_item" style="margin-right: 80px">
                  <div class="number" style="color: #fc6e19">
                    <animate-number
                      from="0"
                      :to="dsjzx[1].value"
                      duration="5000"
                      easing="easeOutQuad"
                      style="display: block"
                    ></animate-number>
                  </div>
                  <div class="name">{{ dsjzx[1].tip }}</div>
                </div>
              </div>
              <div class="mt30 flex-center">
                <div class="t_item" style="margin-right: 37px">
                  <div class="number" style="color: #26d4ff">
                    <animate-number
                      from="0"
                      :to="dsjzx[2].value"
                      duration="5000"
                      easing="easeOutQuad"
                      style="display: block"
                    ></animate-number>
                  </div>
                  <div class="name">{{ dsjzx[2].tip }}</div>
                </div>
                <div class="t_item" style="margin-right: 80px">
                  <div class="number" style="color: #5470ff">
                    <animate-number
                      from="0"
                      :to="dsjzx[3].value"
                      duration="5000"
                      easing="easeOutQuad"
                      style="display: block"
                    ></animate-number>
                  </div>
                  <div class="name">{{ dsjzx[3].tip }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="b_title">安全数据分析引擎</div>
        <div class="a_title">整体安全保障体系闭环</div>
      </div>
    </div>
    <div class="right">
      <div class="bottom_list">
        <div class="bottom_item bg2">
          <div class="b_title">数据变化趋势</div>
          <trend :chart="chart"></trend>
        </div>
      </div>
      <div class="bottom_list mt30">
        <div class="bottom_item bg3">
          <div class="b_title">安全告警</div>
          <el-tabs v-model="activeName">
            <el-tab-pane label="未确认" name="1"></el-tab-pane>
            <el-tab-pane label="已确认" name="2"></el-tab-pane>
            <el-tab-pane label="已解决" name="3"></el-tab-pane>
          </el-tabs>
          <div class="contain">
            <div class="rank" v-if="numbers.length > 0">
              <div class="r_item">
                <div class="top">
                  <div class="icon">
                    <img src="@ass/img/1.0.0/icon_aqjg_gao.svg" alt="" />
                  </div>
                  <div class="type">高</div>
                </div>
                <div class="name">
                  <animate-number
                    from="0"
                    :to="numbers[0].value"
                    easing="easeOutQuad"
                    style="display: block"
                  ></animate-number>
                </div>
              </div>
              <div class="r_item ml50 mr50">
                <div class="top">
                  <div class="icon">
                    <img src="@ass/img/1.0.0/icon_aqjg_zhong.svg" alt="" />
                  </div>
                  <div class="type">中</div>
                </div>
                <div class="name">
                  <animate-number
                    from="0"
                    :to="numbers[1].value"
                    easing="easeOutQuad"
                    style="display: block"
                  ></animate-number>
                </div>
              </div>
              <div class="r_item">
                <div class="top">
                  <div class="icon">
                    <img src="@ass/img/1.0.0/icon_aqjg_di.svg" alt="" />
                  </div>
                  <div class="type">低</div>
                </div>
                <div class="name">
                  <animate-number
                    from="0"
                    :to="numbers[2].value"
                    easing="easeOutQuad"
                    style="display: block"
                  ></animate-number>
                </div>
              </div>
            </div>
            <div class="d-flex jc-center body-box mt40">
              <dv-scroll-board
                :config="config"
                style="width: 210px; height: 350px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import trend from './components/trend.vue'
import distribution from './components/distribution.vue'
import pie from '@cm/charts/pie'
export default {
  components: {
    pie,
    distribution,
    trend,
  },

  data() {
    return {
      chart: [],
      form: [],
      form2: [],
      form3: [],
      aqgj: [],
      dsjzx: [],
      mgsjfb: [],
      distributionOption: [],
      numbers: [], //安全告警高中低
      config: {
        header: ['发生时间', '等级'],
        data: [],
        rowNum: 8, //表格行数
        headerHeight: 35,
        headerBGC: '#0F263D', //表头
        oddRowBGC: '#0F263D', //奇数行
        evenRowBGC: '#0F263D', //偶数行
        columnWidth: [160],
        align: ['center'],
      },
      activeName: '1',
      configure: {},
    }
  },

  filters: {
    getlv(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '低'
          break
        case 2:
          text = '中'
          break
        case 3:
          text = '高'
          break
      }
      return text
    },
    getcolor(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '#1283E3'
          break
        case 2:
          text = '#FCC935'
          break
        case 3:
          text = '#E87766'
          break
      }
      return text
    },
  },

  created() {
    this.getnortheastData()
  },

  watch: {
    activeName: {
      handler(val) {
        if (this.aqgj.length > 0) {
          this.getnumber(val)
          this.getconfig(val)
        }
      },
      immediate: true,
    },

    mgsjfb: {
      handler(val) {
        if (val.length > 0) {
          this.getconfigure()
        }
      },
      immediate: true,
    },
  },

  methods: {
    open(url) {
      window.open(url)
    },
    getconfig(val) {
      let arr = []
      switch (Number(val)) {
        case 1:
          arr = this.form.map((item) => {
            return [
              `<span title='${item.occurrence_time}'>${item.occurrence_time}</span>`,
              `<span style="color:${this.$options.filters['getcolor'](
                item.lv
              )};">${this.$options.filters['getlv'](item.lv)}</span>`,
            ]
          })
          break
        case 2:
          arr = this.form2.map((item) => {
            return [
              `<span title='${item.occurrence_time}'>${item.occurrence_time}</span>`,
              `<span style="color:${this.$options.filters['getcolor'](
                item.lv
              )};">${this.$options.filters['getlv'](item.lv)}</span>`,
            ]
          })
          break
        case 3:
          arr = this.form3.map((item) => {
            return [
              `<span title='${item.occurrence_time}'>${item.occurrence_time}</span>`,
              `<span style="color:${this.$options.filters['getcolor'](
                item.lv
              )};">${this.$options.filters['getlv'](item.lv)}</span>`,
            ]
          })
          break
      }
      this.config = Object.assign({}, this.config, { data: arr })
    },

    getnumber(val) {
      switch (Number(val)) {
        case 1:
          this.numbers = this.aqgj.slice(0, 3)
          break
        case 2:
          this.numbers = this.aqgj.slice(3, 6)
          break
        case 3:
          this.numbers = this.aqgj.slice(6, 9)
          break
      }
    },
    async getnortheastData() {
      const {
        chart,
        form,
        form2,
        form3,
        list: { aqgj, dsjzx, mgsjfb },
      } = await this.$http.get('/index/northeastData')
      this.chart = chart
      this.form = form
      this.form2 = form2
      this.form3 = form3
      this.aqgj = aqgj
      this.dsjzx = dsjzx
      this.mgsjfb = mgsjfb
      this.distributionOption = mgsjfb.slice(3, 6)
      this.getnumber(this.activeName)
      this.getconfig(this.activeName)
    },

    getconfigure() {
      const vallist = this.mgsjfb.slice(0, 3)
      let data1 = vallist.map((item) => {
        let color =
          item.name == 'Website_account'
            ? '#FCC935'
            : item.name == 'bank_card_number'
            ? '#1283E3'
            : '#FE6500'
        return {
          value: item.value,
          name: item.tip,
          itemStyle: {
            color,
          },
        }
      })
      this.configure = {
        legend: {
          bottom: '0%',
          itemWidth: 12,
          itemHeight: 12,
          textStyle: {
            color: '#fff',
          },
          itemGap: 21,
          orient: 'vertical',
        },

        series: [
          {
            top: -60,

            width: '200',
            type: 'pie',
            left: '30px',
            radius: ['65%', '80%'],
            avoidLabelOverlap: false,

            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold',
              },
            },

            label: {
              show: false,
            },

            data: data1,
          },
        ],
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#northeastData {
  display: flex;
  padding: 0 20px;
  img {
    width: 100%;
    height: auto;
  }
  .ml128mr109 {
    margin: 0 128px 0 109px;
  }
  .bottom_list {
    .b_title {
      position: absolute;
      top: -11px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
    }
    .b_name {
      padding-top: 60px;
      font-size: 14px;
      text-align: center;
      margin-bottom: 10px;
      color: #ffffff;
      line-height: 14px;
    }
    .bottom_item {
      position: relative;
    }

    .bg1 {
      width: 260px;
      height: 443px;
      background: url('@ass/img/1.0.0/icon_dbnydsj_nrbg1.svg') no-repeat;
    }
    .bg2 {
      width: 260px;
      height: 283px;
      background: url('@ass/img/1.0.0/icon_dbnydsj_nrbg3.svg') no-repeat;
    }
    ::v-deep .bg3 {
      box-sizing: border-box;
      padding: 47px 20px 0;
      width: 260px;
      height: 603px;
      background: url('@ass/img/1.0.0/icon_dbnydsj_nrbg4.svg') no-repeat;
      .el-tabs__nav-wrap::after {
        display: none;
      }
      .el-tabs__item {
        color: #fff;
        font-size: 12px;
        padding: 0 18px;
      }
      .el-tabs__item.is-active {
        color: #eb7d31;
      }
      .el-tabs__active-bar {
        background-color: #eb7d31;
      }
    }
    .contain {
      .rank {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        .r_item {
          display: flex;
          flex-direction: column;
          align-items: center;
          .top {
            display: flex;
            align-items: center;
            .icon {
              height: 14px;
              width: auto;
              img {
                width: 100%;
              }
            }
            .type {
              font-size: 12px;
              margin-left: 6px;
              color: #c2daff;
              line-height: 12px;
            }
          }
          .name {
            margin-top: 8px;
            font-family: 'num';
            font-size: 24px;
            font-weight: bold;
            color: #ffffff;
            line-height: 32px;
          }
        }
      }
    }
    .right {
    }
  }
  .center {
    padding: 0 30px;
    width: 1290px;
    .center_top {
      position: relative;
      height: 188px;
      background: url('@ass/img/1.0.0/icon_dbnydsj_aqjkqbxt.svg') no-repeat;
      background-size: 380px 84px;
      background-position: 460px 54px;
      .img_center_top {
        position: absolute;
        width: 1240px;
        height: auto;
        bottom: 0;
        left: 50px;
      }
    }
    .contain {
      .tu {
        width: 113px;
        margin-top: 10px;
      }
      .item {
        margin-right: 38px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .tu {
          height: 24px;
          width: 24px;
          text-align: center;
          img {
            height: 100%;
            width: auto;
          }
        }
      }
      .name {
        text-align: center;
        margin-top: 16px;
        font-size: 17px;
        color: #c2daff;
        line-height: 20px;
      }
      .cneter_item_list {
        display: flex;
        margin: 18px 0 0 125px;
      }
      .itemlist {
        margin-top: 60px;
        margin-left: 197px;
      }
    }
    .contain_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      width: 150px;
      .item_tu {
        width: 80px;
      }
      .item_name {
        position: absolute;
        bottom: 0;
        font-size: 17px;
        font-weight: bold;
        color: #ffffff;
        line-height: 17px;
      }
    }
    .center_center {
      margin-top: 40px;
      margin-bottom: 30px;
      position: relative;
      height: 275px;
      background: url('@ass/img/1.0.0/icon_dbnydsj_sjztxt.svg') no-repeat;
      background-size: 775px 182px;
      background-position: 263px 54px;
      .img_center_top {
        position: absolute;
        width: 1240px;
        height: auto;
        bottom: 0;
        left: 50px;
      }
    }
    .center_bottom {
      margin-top: 40px;
      margin-bottom: 30px;
      position: relative;
      height: 370px;
      background: url('@ass/img/1.0.0/icon_dbnydsj_dsjzxbg.svg') no-repeat;
      background-size: 920px 281px;
      background-position: 247px 54px;
      .a_title {
        position: absolute;
        bottom: 28px;
        right: 445px;
        font-size: 20px;
        font-weight: bold;
        color: #c2daff;
        line-height: 20px;
      }
      .b_title {
        position: absolute;
        bottom: 68px;
        left: 520px;
        font-weight: bold;
        font-size: 17px;
        color: #c2daff;
        line-height: 22px;
      }
      .img_center_top {
        position: absolute;
        width: 1240px;
        height: auto;
        bottom: 0;
        left: 50px;
      }
      .center_bottom_list {
        display: flex;
        .one_item {
          margin-top: 71px;
          margin-left: 60px;
        }
        .tow_item {
          .layer,
          .layer2 {
            display: flex;
            margin-top: 43px;
            margin-left: -10px;
          }
          .layer2 {
            margin-top: 48px;
            margin-left: 72px;
          }
        }
        .three_item {
          margin-top: 53px;
          margin-left: 100px;
          .t_item {
            display: flex;
            flex-direction: column;
            align-items: center;
            .number {
              font-family: 'num';
              font-size: 41px;
              font-weight: bold;
            }
            .name {
              font-size: 16px;
              color: #6483a4;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
