<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-14 17:30:28
 * @LastEditTime: 2022-04-28 10:26:42
 * @LastEditors: cyy
 * @Description: 统一密码基础设施
 * @FilePath: \networksecurity\src\views\leftProduct\unifiedPassword.vue
-->
 <template>
  <div id="unifiedPassword">
    <div class="item">
      <div class="lp_title">统一密码基础设施</div>
      <div class="basics" v-if="list.length > 0">
        <div class="list">
          <div class="l_item">
            <div class="tu">
              <img src="@ass/img/1.0.0/icon_4+4_yypt.svg" alt="" />
            </div>
            <div class="right">
              <div class="number">
                <animate-number
                  from="0"
                  :to="list[0].value"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </div>
              <div class="name">{{ list[0].tip }}</div>
            </div>
          </div>
          <div class="l_item">
            <div class="tu">
              <img src="@ass/img/1.0.0/icon_4+4_yyss.svg" alt="" />
            </div>
            <div class="right">
              <div class="number">
                <animate-number
                  from="0"
                  :to="list[1].value"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </div>
              <div class="name">{{ list[1].tip }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="password_resource">
        <div
          ref="monthWorkOrder"
          class="echarts-box"
          style="width: 360px; height: 310px"
        ></div>
      </div>
      <div class="resource_call">
        <div
          ref="resource"
          class="echarts-box"
          style="width: 360px; height: 280px"
        ></div>
      </div>
    </div>
  </div>
</template>
 
 <script>
export default {
  name: 'unifiedPassword',

  watch: {
    chart: {
      handler(val) {
        if (val.length > 0) {
          this.initSource()
        }
      },
      immediate: true,
    },
    list: {
      handler(val) {
        if (val.length > 0) {
          this.initMonthWorkOrder()
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.initSource()
    this.initMonthWorkOrder()
  },

  props: {
    chart: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    initSource() {
      let myChart = this.$echarts.getInstanceByDom(this.$refs.resource)

      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.resource)
      }

      let x_axis = this.chart.map((item) => item.x_axis)
      let y_axis = this.chart.map((item) => item.y_axis)
      let option = {
        tooltip: {
          trigger: 'axis',
        },

        xAxis: {
          type: 'category',
          data: x_axis,
        },
        yAxis: {
          type: 'value',
        },
        grid: {
          right: '0%',
          left: '15%',
          width: '90%',
        },

        title: {
          text: '资源调用趋势（次）',
          left: 'center',
          bottom: '0',
          textStyle: {
            fontSize: '17px',
            color: '#fff',
          },
        },

        series: [
          {
            data: y_axis,
            type: 'line',
            smooth: true, 
            symbol: 'none',
            sampling: 'lttb',

            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 1,
                    color: 'rgb(15, 47, 71)',
                  },
                  {
                    offset: 0,
                    color: 'rgb(37, 123, 134)', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },

            itemStyle: {
              color: 'transparent',
            },
          },
        ],
      }
      myChart.setOption(option)
    },

    initMonthWorkOrder() {
      let myChart = this.$echarts.getInstanceByDom(this.$refs.monthWorkOrder)

      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.monthWorkOrder)
      }
      let vallist = this.list.slice(2, 5)
      let val = vallist.map((item) => {
        const type =
          item.name == 'pass_zc'
            ? '正常'
            : item.name == 'pass_sg'
            ? '失管'
            : '失控'
        const color =
          item.name == 'pass_zc'
            ? '#44C8EE'
            : item.name == 'pass_sg'
            ? '#ED576E'
            : '#FDD028'
        return {
          value: item.value,
          name: type,
          itemStyle: {
            color,
          },
        }
      })
      let ledDtat = val.map((item) => item.name)
      let option = {
        title: {
          text: '密码资源监控',
          left: 'center',
          bottom: '0%',
          textStyle: {
            fontSize: '17px',
            color: '#fff',
          },
        },

        tooltip: {
          trigger: 'item',
        },

        grid: {
          height: '49%',
          top: '2%',
          widt: '90%',
        },

        legend: {
          bottom: '10%',
          left: 'center',
          textStyle: {
            color: '#fff',
          },
          data: ledDtat,
        },

        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['50%', '70%'],
            label: {
              show: true,
              alignTo: 'edge',
              formatter: ' {time|{c}}',
              minMargin: 5,
              edgeDistance: 10,
              lineHeight: 15,
              rich: {
                time: {
                  fontSize: 10,
                  color: '#fff',
                },
              },
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold',
              },
            },

            labelLine: {
              length: 7,
              length2: 11,
              show: true,
            },

            data: val,
            top: -14,
            height: '85%',
          },
        ],
      }
      myChart.setOption(option)
    },
  },
}
</script>

<style lang="scss" src="./index.scss" scoped>
</style>
 
 <style lang="scss" scoped>
#unifiedPassword {
  .password_resource {
    margin-top: 72px;
    display: flex;
    justify-content: center;
  }
  .resource_call {
    margin-top: 20px;
  }
}
</style>