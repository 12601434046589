<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-19 15:31:47
 * @LastEditTime: 2022-04-26 17:02:03
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \networksecurity\src\components\charts\pie.vue
-->
<template>
  <div
    class="attackerposture"
    style="width: 100%; height: 250px"
    :style="`width: ${width || '100%'}; height: ${height || '100%'}`"
    ref="attackerposture"
  ></div>
</template>

<script>
export default {
  name: 'pie',
  data() {
    return {}
  },

  watch: {
    acquiredOption: {
      handler(val) {
        if (val && Object.keys(val).length) {
          this.getAttackerposture()
        }
      },
      immediate: true,
      deep: true,
    },
  },

  props: {
    acquiredOption: {
      type: Object,
      default: () => {},
    },

    height: [String],
    width: [String],
  },

  // mounted() {
  //   this.getAttackerposture()
  // },

  methods: {
    getAttackerposture() {
      let myChart = this.$echarts.getInstanceByDom(this.$refs.attackerposture)

      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.attackerposture)
      }

      let option = Object.assign(
        {},
        {
          tooltip: {
            trigger: 'item',
          },
          title: {
            show: false,
            text: 'Main Title',
            left: 'center',
            top: 'center',
            textStyle: {
              fontSize: 16,
              color: '#fff',
            },
          },
          legend: {
            top: 'center',
            right: '15',
            orient: 'vertical',
            itemWidth: 12,
            itemHeight: 12,
            textStyle: {
              color: '#fff',
            },
          },

          series: [
            {
              width: '200',
              right: '30%',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,

              emphasis: {
                label: {
                  show: false,
                  fontSize: '40',
                  fontWeight: 'bold',
                },
              },
              labelLine: {
                show: false,
              },
              label: {
                show: false,
                alignTo: 'edge',
                formatter: ' {time|{c}}',
                rich: {
                  time: {
                    fontSize: 10,
                    color: '#fff',
                  },
                },
              },
              data: [
                {
                  value: 1048,
                  name: 'web恶意访问',
                  itemStyle: {
                    color: '#4BE297',
                  },
                },
                {
                  value: 735,
                  name: '其他',
                  itemStyle: {
                    color: '#F4D00D',
                  },
                },
              ],
            },
          ],
        },
        this.acquiredOption
      )

      myChart.setOption(option)
    },
  },
}
</script>

<style>
</style>