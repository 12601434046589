var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[[_c('div',{ref:"appRef",class:_vm.$route.name != 'login' ? 'appindex' : ''},[(_vm.$route.name != 'login')?_c('div',{staticClass:"title",attrs:{"id":"pageTitle"}},[_c('div',{staticClass:"nav left",class:{ selected: _vm.$route.name == 'securityoperationCenter' }},[_c('div',{staticClass:"text",on:{"click":function($event){_vm.$router.push(
                { path: '/securityoperationCenter' },
                function (onComplete) {},
                function (onAbort) {}
              )}}},[_vm._v(" 运营中心 ")])]),_c('div',{staticClass:"nav left",class:{ selected: _vm.$route.name == 'powersupplyService' }},[_c('div',{staticClass:"text",on:{"click":function($event){_vm.$router.push(
                { path: '/powersupplyService' },
                function (onComplete) {},
                function (onAbort) {}
              )}}},[_vm._v(" 供电服务指挥 ")])]),_c('div',{staticClass:"nav left",class:{ selected: _vm.$route.name == 'northeastData' }},[_c('div',{staticClass:"text",on:{"click":function($event){_vm.$router.push(
                { path: '/northeastData' },
                function (onComplete) {},
                function (onAbort) {}
              )}}},[_vm._v(" 东北能源大数据中心 ")])]),_c('div',{staticClass:"center cp",on:{"click":function($event){_vm.$router.push(
              { path: '/' },
              function (onComplete) {},
              function (onAbort) {}
            )}}},[_vm._v(" 品阔网络安全综合防护平台 ")]),_c('div',{staticClass:"nav right",class:{ selected: _vm.$route.name == 'safetyProtection' }},[_c('div',{staticClass:"text",on:{"click":function($event){_vm.$router.push(
                { path: '/safetyProtection' },
                function (onComplete) {},
                function (onAbort) {}
              )}}},[_vm._v(" 用采安全防护 ")])]),_c('div',{staticClass:"nav right",class:{ selected: _vm.$route.name == 'smartEnergy' }},[_c('div',{staticClass:"text",on:{"click":function($event){_vm.$router.push(
                { path: '/smartEnergy' },
                function (onComplete) {},
                function (onAbort) {}
              )}}},[_vm._v(" 智慧能源调控 ")])]),_c('div',{staticClass:"nav right",class:{ selected: _vm.$route.name == 'marketingData' }},[_c('div',{staticClass:"text",on:{"click":function($event){_vm.$router.push(
                { path: '/marketingData' },
                function (onComplete) {},
                function (onAbort) {}
              )}}},[_vm._v(" 营销数据脱敏 ")])])]):_vm._e(),_c('div',{staticClass:"main",style:(_vm.$route.path == '/login' ? 'top:0' : ' top: 115px;')},[_c('router-view')],1)])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }