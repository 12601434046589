/*
 * @Author: cyy
 * @version:
 * @Date: 2022-04-12 10:48:48
 * @LastEditTime: 2022-04-26 15:14:42
 * @LastEditors: cyy
 * @Description:
 * @FilePath: \networksecurity\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/common.scss'
import util from '@ass/js/util'

Vue.prototype.$http = util

// svg图的一些插件
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

// 引入element
import elementui from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(elementui)

// 图表 
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 数字动态
import animateNumber from '@cm/animationNumber'
import earth from '@cm/earth'
Vue.component('animateNumber', animateNumber)
Vue.component('earthModel', earth)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
