<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-20 11:57:10
 * @LastEditTime: 2022-04-28 11:02:57
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \networksecurity\src\views\securityoperationCenter\components\distribution.vue
-->
<template>
  <div
    class="distribution"
    style="width: 100%; height: 250px"
    ref="distribution"
  ></div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    list: {
      handler(val) {
        if (val.length > 0) {
          this.getdistribution()
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.getdistribution()
  },
  methods: {
    getdistribution() {
      let myChart = this.$echarts.getInstanceByDom(this.$refs.distribution)

      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.distribution)
      }
      let val = this.list.map((item) => {
        let colorStops = []
        switch (item.name) {
          case 'risk_level_1':
            colorStops = [
              {
                offset: 0,
                color: '#71F6F7', // 0% 处的颜色
              },
              {
                offset: 1,
                color: '#0387E1', // 100% 处的颜色
              },
            ]
            break
          case 'risk_level_2':
            colorStops = [
              {
                offset: 0,
                color: '#FFDBAD', // 0% 处的颜色
              },
              {
                offset: 1,
                color: '#FDCF19', // 100% 处的颜色
              },
            ]
            break
          case 'risk_level_3':
            colorStops = [
              {
                offset: 0,
                color: '#FFB5B5', // 0% 处的颜色
              },
              {
                offset: 1,
                color: '#C7902B', // 100% 处的颜色
              },
            ]
            break
          case 'risk_level_4':
            colorStops = [
              {
                offset: 0,
                color: '#FF5D5D', // 0% 处的颜色
              },
              {
                offset: 1,
                color: '#C72B2B', // 100% 处的颜色
              },
            ]
            break
          case 'risk_level_5':
            colorStops = [
              {
                offset: 0,
                color: '#3A3A3A', // 0% 处的颜色
              },
              {
                offset: 1,
                color: '#3A3A3A', // 100% 处的颜色
              },
            ]
            break
        }
        return {
          value: item.value,
          itemStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: colorStops,
              global: false, // 缺省为 false
            },
          },
        }
      })

      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: {
          left: '15%',
        },

        xAxis: {
          type: 'category',
          data: [
            {
              value: '正常',
              textStyle: {
                color: '#fff',
              },
            },
            {
              value: '低危',
              textStyle: {
                color: '#fff',
              },
            },
            {
              value: '中危',
              textStyle: {
                color: '#fff',
              },
            },
            {
              value: '高危',
              textStyle: {
                color: '#fff',
              },
            },
            {
              value: '危急',
              textStyle: {
                color: '#fff',
              },
            },
          ],
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              // type: 'dashed',
              color: '#293A5F',
            },
          },
        },
        series: [
          {
            barMaxWidth: '20px',

            data: val,
            type: 'bar',
          },
        ],
      }
      myChart.setOption(option)
    },
  },
}
</script>

<style>
</style>