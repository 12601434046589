/*
 * @Author: cyy
 * @version:
 * @Date: 2022-06-09 10:51:49
 * @LastEditTime: 2022-06-09 11:07:30
 * @LastEditors: cyy
 * @Description:
 * @FilePath: \networksecurity\src\store\index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {
    open(url) {
      window.open(url)
    },
  },
  modules: {},
})
