<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-20 12:01:50
 * @LastEditTime: 2022-04-26 17:03:25
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \networksecurity\src\views\securityoperationCenter\components\risk.vue
-->
<template>
  <div
    ref="resource"
    class="echarts-box"
    style="width: 280px; height: 351px"
  ></div>
</template>

<script>
export default {
  mounted() {
    this.initSource()
  },

  props: {
    chartlist: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    chartlist: {
      handler(val) {
        if (val.length > 0) {
          this.initSource()
        }
      },
    },
  },

  methods: {
    initSource() {
      let myChart = this.$echarts.getInstanceByDom(this.$refs.resource)

      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.resource)
      }
      let x_axis = this.chartlist.map((item) => {
        return {
          value: item.x_axis,
          textStyle: {
            color: '#fff',
          },
        }
      })
      let y_axis = this.chartlist.map((item) => {
        return item.y_axis
      })
      let option = {
        tooltip: {
          trigger: 'axis',
        },

        xAxis: {
          type: 'category',
          data: x_axis,
        },
        yAxis: {
          type: 'value',
          show: false,
        },
        grid: {
          right: '0%',
          left: '10%',
          width: '90%',
          height: '150',
          top: '10%',
        },

        series: [
          {
            data: y_axis,
            type: 'line',
            smooth: true, 
            symbol: 'none',
            sampling: 'lttb', 
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 1,
                    color: 'rgb(15, 47, 71)',
                  },
                  {
                    offset: 0,
                    color: 'rgb(37, 123, 134)', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },

            itemStyle: {
              color: 'transparent',
            },
          },
        ],
      }
      myChart.setOption(option)
    },
  },
}
</script>

<style>
</style>