<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-15 14:50:34
 * @LastEditTime: 2022-06-10 11:28:01
 * @LastEditors: cyy
 * @Description: 数据安全
 * @FilePath: \networksecurity\src\views\rightProduct\dataSecurity.vue
-->
<template>
  <div
    class="dataSecurity contianclick cp"
    @click="open('http://20.66.250.86:9095')"
  >
    <div class="item ml20 mr20">
      <div class="lp_title">数据安全</div>
      <div class="f_title mb20">数据安全合规管控平台</div>
      <div class="control mb10 flex-center">
        <div class="c_item mr10">统一数据访问权限管理</div>
        <div class="c_item">场景化数据过程安全管控</div>
      </div>
      <div class="control flex-center">
        <div class="c_item mr10">按需构建数据安全能力</div>
        <div class="c_item">数据安全全景视图</div>
      </div>
      <div class="basics mt20" v-if="list.length > 0">
        <div class="list">
          <div class="l_item">
            <div class="tu">
              <img src="@ass/img/1.0.0/icon_4+4-2_mgsj.svg" alt="" />
            </div>

            <div class="right">
              <div class="number">
                <animate-number
                  from="0"
                  :to="list[3].value"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </div>
              <div class="name">{{ list[3].tip }}</div>
            </div>
          </div>
          <div class="l_item">
            <div class="tu">
              <img src="@ass/img/1.0.0/icon_4+4-2_sjk.svg" alt="" />
            </div>
            <div class="right">
              <div class="number">
                <animate-number
                  from="0"
                  :to="list[4].value"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </div>
              <div class="name">{{ list[4].tip }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="f_title mt30 mb20">数据脱敏&水印溯源系统</div>
      <div class="dataout flex-center">
        <div class="o_item mr10">
          <div class="fs1 mb20 _center">智能扫描</div>
          <div class="fs1 mb20 _center">脱敏模板</div>
          <div class="fs1 mb20 _center">数据对比</div>
          <div class="fs1 _center">修正脱敏策略</div>
        </div>
        <div class="o_item">
          <div class="fs1 mb20 _center">自动识别</div>
          <div class="fs1 mb20 _center">多种水印规则</div>
          <div class="fs1 mb20 _center">高伪装</div>
          <div class="fs1 _center">高精度溯源</div>
        </div>
      </div>
      <div class="f_title mt30 mb20">应用数据安全审计系统</div>
      <div
        class="applicateData"
        style="width: 100%; height: 200px"
        ref="applicateData"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dataSecurity',

  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    list: {
      handler(val) {
        if (val.length > 0) {
          this.getapplicateData()
        }
      },
      immediate: true,
    },
  },

  data() {
    return {}
  },

  mounted() {
    this.getapplicateData()
  },

  methods: {
    open(url) {
      window.open(url)
    },
    getapplicateData() {
      let myChart = this.$echarts.getInstanceByDom(this.$refs.applicateData)
      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.applicateData)
      }

      let list = this.list.slice(0, 3)

      let data1 = list.map((item) => {
        let text =
          item.name == 'injection_attack'
            ? '注入攻击'
            : item.name == 'password_attack'
            ? '口令攻击'
            : '漏洞攻击'
        return text
      })

      let data2 = this.list.map((item) => {
        let text2 =
          item.name == 'injection_attack'
            ? '#FFC055'
            : item.name == 'password_attack'
            ? '#61FBFB'
            : '#E93434'
        return {
          value: item.value,
          itemStyle: {
            color: text2,
          },
        }
      })

      let option = {
        tooltip: {
          trigger: 'axis',
        },
        title: {
          text: '风险统计',
          left: 'center',
          top: '0',
          textStyle: {
            fontSize: '17px',
            color: '#fff',
          },
        },
        grid: {
          left: '20%',
          top: 40,
          height: '40%',
        },

        xAxis: [
          {
            type: 'category',
            data: data1,
            axisLabel: {
              show: true,
              color: '#fff',
            },
          },
        ],
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} ',
          },
          splitLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              // type: 'dashed',
              color: '#293A5F',
            },
          },
        },
        series: [
          {
            barMaxWidth: '21px',
            data: data2,
            type: 'bar',
          },
        ],
      }
      myChart.setOption(option)
    },
  },
}
</script>

<style lang="scss" src="./index.scss" scoped></style>

<style lang="scss" scoped>
.dataSecurity {
  .dataout {
    justify-content: center;
    .o_item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 160px;
      height: 172px;
      background: rgba(34, 108, 152, 0.28);
      box-shadow: inset 0px 0px 9px 0px #0082f3;
      opacity: 0.86;
      border: 2px solid #0b808c;
      .fs1 {
        font-size: 19px;
        color: #ffffff;
        line-height: 19px;
      }
    }
  }
  .control {
    justify-content: center;
    .c_item {
      width: 180px;
      height: 31px;
      background: rgba(34, 108, 152, 0.28);
      box-shadow: inset 0px 0px 9px 0px #0082f3;
      opacity: 0.86;
      border: 2px solid #0b808c;
      font-size: 16px;
      color: #ffffff;
      text-align: center;
      line-height: 31px;
    }
  }
}
</style>
