<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-15 14:50:02
 * @LastEditTime: 2022-06-10 11:27:47
 * @LastEditors: cyy
 * @Description: 设备安全
 * @FilePath: \networksecurity\src\views\rightProduct\equipment.vue
-->
<template>
  <div class="equipment">
    <div class="item ml20 mr20">
      <div class="lp_title">设备安全</div>
      <div class="contianclick cp" @click="open('https://20.66.50.190/CEMS/')">
        <div class="f_title mb25">桌面终端管理系统</div>
        <div class="basics" v-if="list.length > 0">
          <div class="list">
            <div class="l_item">
              <div class="tu">
                <img src="@ass/img/1.0.0/icon_4+4-2_fhzd.svg" alt="" />
              </div>
              <div class="right">
                <div class="number">
                  <animate-number
                    from="0"
                    :to="list[0].value"
                    easing="easeOutQuad"
                    style="display: block"
                  ></animate-number>
                </div>
                <div class="name">{{ list[0].tip }}</div>
              </div>
            </div>
            <div class="l_item">
              <div class="tu">
                <img src="@ass/img/1.0.0/icon_4+4-2_rkls.svg" alt="" />
              </div>
              <div class="right">
                <div class="number">
                  <animate-number
                    from="0"
                    :to="list[1].value"
                    easing="easeOutQuad"
                    style="display: block"
                  ></animate-number>
                </div>
                <div class="name">{{ list[1].tip }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="p1 flex-center">
          <img src="@ass/img/1.0.0/icon_4+4-2_zdaqglzx.svg" alt="" />
        </div>
      </div>
      <div class="contianclick cp" @click="open('https://10.160.252.86/admin')">
        <div class="f_title mb25">终端固件漏洞监测系统</div>
        <div class="basics mb36" v-if="list.length > 0">
          <div class="list">
            <div class="l_item">
              <div class="tu">
                <img src="@ass/img/1.0.0/icon_4+4-2_jczd.svg" alt="" />
              </div>
              <div class="right">
                <div class="number">
                  <animate-number
                    from="0"
                    :to="list[2].value"
                    easing="easeOutQuad"
                    style="display: block"
                  ></animate-number>
                </div>
                <div class="name">{{ list[2].tip }}</div>
              </div>
            </div>
            <div class="l_item">
              <div class="tu">
                <img src="@ass/img/1.0.0/icon_4+4-2_ldzs.svg" alt="" />
              </div>
              <div class="right">
                <div class="number">
                  <animate-number
                    from="0"
                    :to="list[3].value"
                    easing="easeOutQuad"
                    style="display: block"
                  ></animate-number>
                </div>
                <div class="name">{{ list[3].tip }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="contianclick cp"
        @click="open('https://10.160.180.152/sguap/sgemm/main.jsp')"
      >
        <div class="f_title mb15">移动安全监测系统</div>
        <template v-if="list.length > 0">
          <div class="fs18 colorw _center">{{ list[4].tip }}</div>
          <div class="number">
            <animate-number
              from="0"
              :to="list[4].value"
              easing="easeOutQuad"
              style="display: block"
            ></animate-number>
          </div>
          <div
            class="press_contain flex-center"
            v-for="i in percentage"
            :key="i.name"
          >
            <span class="sp1">{{ i.tip }}</span>
            <el-progress
              :percentage="Number(i.value) > 100 ? 100 : Number(i.value)"
              :format="format(i.value)"
            ></el-progress>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'equipment',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {}
  },

  computed: {
    percentage() {
      return this.list.slice(5, 9)
    },
  },

  mounted() {},

  methods: {
    open(url) {
      window.open(url)
    },
    format(percentage) {
      return () => {
        return percentage
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.equipment {
  .mb36 {
    margin-bottom: 36px;
  }
  .number {
    font-size: 34px;
    font-weight: bold;
    color: #3ffeff;
    margin: 10px 0;
    line-height: 34px;
    text-align: center;
  }
  ::v-deep .press_contain {
    margin-bottom: 17px;
    justify-content: center;
    .el-progress {
      width: 180px;
      margin-left: 10px;
      .el-progress__text {
        color: #219299;
      }
      .el-progress-bar__outer {
        background: transparent;
      }
      .el-progress-bar__inner {
        background: linear-gradient(
          90deg,
          rgba(13, 35, 57, 0) 0%,
          #28bdbe 100%
        );
      }
    }
    .sp1 {
      font-size: 19px;
      color: #ffffff;
      line-height: 19px;
    }
  }
  .p1 {
    margin: 25px 0;
    img {
      height: 60px;
      width: auto;
    }
  }
}
</style>

<style lang="scss" src="./index.scss" scoped></style>
