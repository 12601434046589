<template>
  <div class="home">
    <earthModel></earthModel>
    <div class="center">
      <div class="left main-left-nav cp">
        <div class="list" @click="$router.push({ path: '/leftProduct' })">
          <div class="item">
            <div class="tu">
              <img src="@ass/img/1.0.0/img_sy_01.svg" alt="" />
            </div>
            <div class="name">
              <div class="name_contain">
                <div>统一密码</div>
                <div>基础设施</div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="tu">
              <img src="@ass/img/1.0.0/img_sy_02.svg" alt="" />
            </div>
            <div class="name">
              <div class="name_contain">
                <div>实验仿真平台</div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="tu">
              <img src="@ass/img/1.0.0/img_sy_03.svg" alt="" />
            </div>
            <div class="name">
              <div class="name_contain">
                <div>攻防靶场</div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="tu">
              <img src="@ass/img/1.0.0/img_sy_04.svg" alt="" />
            </div>
            <div class="name">
              <div class="name_contain">
                <div>安全芯片</div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="data"
          @click="$router.push({ path: '/securityoperationCenter' })"
        >
          <div class="data_name">
            <div class="fs1">综合安全态势</div>
          </div>
          <div class="item type1">
            <div class="date_title">
              <div class="left_name">{{ datalist.terminal_risk.tip }} /</div>
              <div class="right_name">{{ datalist.terminals_sum.tip }}</div>
            </div>
            <div class="number">
              <span class="left_number" v-if="num == 0">
                <animate-number
                  from="0"
                  :to="datalist.terminal_risk.value"
                  delay="2000"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </span>
              <span class="left_number" v-else>
                {{ datalist.terminal_risk.value }}
              </span>
              <span class="right_number">
                <span style="margin-left: 5px">/</span>
                <animate-number
                  from="0"
                  :to="datalist.terminals_sum.value"
                  delay="2000"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </span>
            </div>
          </div>
          <div class="item type2">
            <div class="date_title">
              <span class="left_name">{{ datalist.server_risk.tip }} /</span>
              <span class="right_name">{{ datalist.server_sum.tip }}</span>
            </div>
            <div class="number">
              <span class="left_number" v-if="num == 0">
                <animate-number
                  from="0"
                  :to="datalist.server_risk.value"
                  delay="2000"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </span>
              <span class="left_number" v-else>
                {{ datalist.server_risk.value }}
              </span>
              <span class="right_number">
                <span style="margin-left: 5px">/</span>
                <animate-number
                  from="0"
                  :to="datalist.server_sum.value"
                  delay="2000"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </span>
            </div>
          </div>
          <div class="type3">
            <div class="fs1 _center">策略优化分析</div>
            <div class="flex_clumn_list">
              <div class="clumn_list_item">
                <div class="top_title">{{ datalist.strategy_rong.tip }}</div>
                <div class="ty3_number">
                  <span class="numfamily mr5" v-if="num == 0">
                    <animate-number
                      from="0"
                      :to="datalist.strategy_rong.value"
                      delay="2000"
                      easing="easeOutQuad"
                      style="display: block"
                    ></animate-number>
                  </span>
                  <span class="numfamily mr5" v-else>
                    {{ datalist.strategy_rong.value }}
                  </span>
                  条
                </div>
              </div>
              <div class="clumn_list_item">
                <div class="top_title">{{ datalist.strategy_cover.tip }}</div>
                <div class="ty3_number">
                  <span class="numfamily mr5" v-if="num == 0">
                    <animate-number
                      from="0"
                      :to="datalist.strategy_cover.value"
                      delay="2000"
                      easing="easeOutQuad"
                      style="display: block"
                    ></animate-number>
                  </span>
                  <span class="numfamily mr5" v-else>
                    {{ datalist.strategy_cover.value }}
                  </span>
                  条
                </div>
              </div>
              <div class="clumn_list_item">
                <div class="top_title">{{ datalist.strategy_merge.tip }}</div>
                <div class="ty3_number">
                  <span class="numfamily mr5">
                    <animate-number
                      from="0"
                      :to="datalist.strategy_merge.value"
                      delay="2000"
                      easing="easeOutQuad"
                      style="display: block"
                    ></animate-number>
                  </span>
                  条
                </div>
              </div>
            </div>
            <div class="flex_clumn_list">
              <div class="clumn_list_item">
                <div class="top_title">{{ datalist.strategy_just.tip }}</div>
                <div class="ty3_number">
                  <span class="numfamily mr5">
                    <animate-number
                      from="0"
                      :to="datalist.strategy_just.value"
                      delay="2000"
                      easing="easeOutQuad"
                      style="display: block"
                    ></animate-number>
                  </span>
                  条
                </div>
              </div>
              <div class="clumn_list_item">
                <div class="top_title">{{ datalist.strategy_back.tip }}</div>
                <div class="ty3_number">
                  <span class="numfamily mr5">
                    <animate-number
                      from="0"
                      :to="datalist.strategy_back.value"
                      delay="2000"
                      easing="easeOutQuad"
                      style="display: block"
                    ></animate-number>
                  </span>
                  条
                </div>
              </div>
              <div class="clumn_list_item">
                <div class="top_title">
                  {{ datalist.strategy_conflict.tip }}
                </div>
                <div class="ty3_number">
                  <span class="numfamily mr5">
                    <animate-number
                      from="0"
                      :to="datalist.strategy_conflict.value"
                      delay="2000"
                      easing="easeOutQuad"
                      style="display: block"
                    ></animate-number>
                  </span>
                  条
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="name"
        @click="$router.push({ path: '/securityoperationCenter' })"
      >
        <div class="security_center">运营中心</div>
      </div>
      <div class="right main-right-nav">
        <div
          class="Security_situation"
          @click="$router.push({ path: '/securityoperationCenter' })"
        >
          <div class="fs1 _center" style="margin-bottom: 66px">
            安全事件态势
          </div>
          <div class="flex_clumn_list mb70">
            <div class="clumn_list_item">
              <div class="top_title">{{ datalist.spyware.tip }}</div>
              <div class="ty3_number numfamily" style="color: #c55cff">
                <animate-number
                  from="0"
                  :to="datalist.spyware.value"
                  delay="2000"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </div>
            </div>
            <div class="clumn_list_item">
              <div class="top_title">{{ datalist.trojan_horse.tip }}</div>
              <div class="ty3_number numfamily" style="color: #a9cc32">
                <animate-number
                  from="0"
                  :to="datalist.trojan_horse.value"
                  delay="2000"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </div>
            </div>
          </div>
          <div class="flex_clumn_list mb70">
            <div class="clumn_list_item">
              <div class="top_title">{{ datalist.ransomware.tip }}</div>
              <div
                class="ty3_number numfamily"
                style="color: #525cff"
                v-if="num == 0"
              >
                <animate-number
                  from="0"
                  :to="datalist.ransomware.value"
                  delay="2000"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </div>
              <div class="ty3_number numfamily" style="color: #525cff" v-else>
                {{ datalist.ransomware.value }}
              </div>
            </div>
            <div class="clumn_list_item">
              <div class="top_title">{{ datalist.bitcoin_mining.tip }}</div>
              <div
                class="ty3_number numfamily"
                style="color: #fa9c3b"
                v-if="num == 0"
              >
                <animate-number
                  from="0"
                  :to="datalist.bitcoin_mining.value"
                  delay="2000"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </div>
              <div class="ty3_number numfamily" style="color: #fa9c3b" v-else>
                {{ datalist.bitcoin_mining.value }}
              </div>
            </div>
          </div>
          <div class="flex_clumn_list">
            <div class="clumn_list_item">
              <div class="top_title">{{ datalist.banking_trojan.tip }}</div>
              <div class="ty3_number numfamily" style="color: #49b7d3">
                <animate-number
                  from="0"
                  :to="datalist.banking_trojan.value"
                  delay="2000"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </div>
            </div>
            <div class="clumn_list_item">
              <div class="top_title">
                {{ datalist.downloader_trojan.tip }}
              </div>
              <div
                class="ty3_number numfamily"
                style="color: #e039a1"
                v-if="num == 0"
              >
                <animate-number
                  from="0"
                  :to="datalist.downloader_trojan.value"
                  delay="2000"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </div>
              <div class="ty3_number numfamily" style="color: #e039a1" v-else>
                {{ datalist.downloader_trojan.value }}
              </div>
            </div>
          </div>
        </div>
        <div class="list cp" @click="$router.push({ path: '/rightProduct' })">
          <div class="item">
            <div class="name">
              <div class="name_contain">
                <div>主机业务安全</div>
              </div>
            </div>
            <div class="tu">
              <img src="@ass/img/1.0.0/img_sy_05.svg" alt="" />
            </div>
          </div>
          <div class="item">
            <div class="name">
              <div class="name_contain">
                <div>设备安全</div>
              </div>
            </div>
            <div class="tu">
              <img src="@ass/img/1.0.0/img_sy_06.svg" alt="" />
            </div>
          </div>
          <div class="item">
            <div class="name">
              <div class="name_contain">
                <div>网络安全</div>
              </div>
            </div>
            <div class="tu">
              <img src="@ass/img/1.0.0/img_sy_07.svg" alt="" />
            </div>
          </div>
          <div class="item">
            <div class="name">
              <div class="name_contain">
                <div>数据安全</div>
              </div>
            </div>
            <div class="tu">
              <img src="@ass/img/1.0.0/img_sy_08.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom main-bottom-nav">
      <div class="bottom_list">
        <div
          class="bottom_item bg1 cp"
          @click="$router.push({ path: '/marketingData' })"
        >
          <!-- <div class="b_title">营销数据脱敏</div> -->
        </div>
        <div
          class="bottom_item bg2 cp"
          @click="$router.push({ path: '/smartEnergy' })"
        >
          <!-- <div class="b_title">智慧能源调度</div> 
          -->
        </div>
        <div
          class="bottom_item bg3 cp"
          @click="$router.push({ path: '/safetyProtection' })"
        >
          <!-- <div class="b_title">用采安全防护</div>  
       -->
        </div>
        <div
          class="bottom_item bg4 cp"
          @click="$router.push({ path: '/powersupplyService' })"
        >
          <!-- <div class="b_title">供电服务指挥</div>  
          -->
        </div>
        <div
          class="bottom_item bg5 cp"
          @click="$router.push({ path: '/northeastData' })"
        >
          <!-- <div class="b_title">东北大数据能源中心</div> 
         -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'

export default {
  name: 'HomeView',

  data() {
    return {
      num: 0,
      datalist: new Proxy(
        {},
        {
          get() {
            return {}
          },
        }
      ),
    }
  },

  created() {
    this.getsecurityoperationCenter()
  },

  mounted() {
    // '-=3',正常播放时间duration：0.5，所以设置-=0.5.当前duration：3-=3播放时间提前三秒
    const timeline = gsap.timeline()
    timeline
      .fromTo(
        '#pageTitle',
        { y: -document.querySelector('.title').offsetHeight * 2 },
        { y: 0, duration: 1 }
      )
      .fromTo(
        '.main-bottom-nav',
        { y: document.querySelector('.main-bottom-nav').offsetHeight * 2 },
        { y: 0, duration: 1 },
        '-=1'
      )
      .fromTo('.main-left-nav', { x: '-200%' }, { x: 0, duration: 1 })
      .fromTo(
        '.main-right-nav',
        { x: '200%' },
        {
          x: 0,
          duration: 1,
        },
        '-=1'
      )
  },

  destroyed() {
    clearInterval(this.timer)
  },

  methods: {
    async settimer() {
      this.timer = setInterval(async () => {
        this.num += 1
        await this.getsecurityoperationCenter()
      }, 15000)
    },

    async getsecurityoperationCenter() {
      const { list } = await this.$http.get('/index/home', {
        num: this.num,
      })
      this.datalist = list
      if (this.num == 0) {
        setTimeout(() => {
          this.settimer()
        }, 3000)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.home {
  margin: 0 60px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  line-height: 1.5;

  .mr5 {
    margin-right: 3px;
  }
  // 公用样式
  .mb70 {
    margin-bottom: 70px;
  }
  .numfamily {
    font-family: 'num';
  }
  // 小标题的样式
  .fs1 {
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
  }
  // 上文字下数据的列表
  .flex_clumn_list {
    display: flex;
    align-items: center;
    justify-content: space-around;
    .clumn_list_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      .top_title {
        font-size: 14px;
        color: #ffffff;
        text-align: center;
      }
    }
  }
  img {
    width: auto;
    height: 100%;
  }
  .center {
    display: flex;
    width: 100%;
    position: relative;
    z-index: 3;
    .left {
      display: flex;
      align-items: center;
      width: 822px;
    }
    .name {
      cursor: pointer;
      width: 379px;
      flex-shrink: 0;
      // align-self: start;
      .security_center {
        height: 36px;
        width: 220px;
        margin-left: 26px;
        text-align: center;
        line-height: 36px;
        cursor: pointer;
        background: rgba(46, 75, 106, 0.38);
        border-radius: 0 0 4px 4px;
        border: 1px solid rgba(50, 228, 244, 0.28);
      }
    }
    .right {
      flex: 1;
      display: flex;
      align-items: center;
      .Security_situation {
        cursor: pointer;
        padding-top: 5px;
        box-sizing: border-box;
        background: url('@ass/img/1.0.0/img_sy_aqsjbg.png') no-repeat;
        width: 243px;
        height: 463px;
      }
      img {
        float: left !important;
      }
      .name {
        float: right !important;
        padding-right: 26px !important;
        padding-left: 0 !important;
        text-align: right;
      }
    }
    .list {
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 46px;
        .tu {
          height: 119px;
          width: 181px;
          img {
            float: right;
          }
        }
        .name {
          width: 228px;
          height: 105px;
          background: url('@ass/img/1.0.0/img_sy_wzbg.png');
          background-repeat: no-repeat;
          display: flex;
          flex-direction: column;
          justify-content: center;
          box-sizing: border-box;
          padding-left: 40px;
          .name_contain {
            font-size: 26px;
            font-weight: bold;
            color: #ffffff;
          }
        }
      }
      .item:last-child {
        margin-bottom: 0;
      }
    }
    .data {
      width: 361px;
      .data_name {
        width: fit-content;
        height: 30px;
        margin-bottom: 10px;
        background: linear-gradient(
          270deg,
          rgba(47, 75, 147, 0.66) 0%,
          rgba(12, 32, 48, 0) 100%
        );
        border-radius: 1px;
      }
      .item {
        width: 360px;
        padding: 15px 0 0 40px;
        .date_title {
          font-size: 14px;
          margin-bottom: 10px;
          display: flex;
          align-items: flex-end;
          .left_name {
            color: #ffffff;
          }
          .right_name {
            color: #bababa;
          }
        }
        .number {
          display: flex;
          align-items: center;
          font-family: 'num';
          font-size: 26px;
          font-weight: bold;
          line-height: 26px;
          .left_number {
            color: #fed01c;
          }
          .right_number {
            color: #ffffff;
            display: flex;
            align-items: center;
          }
        }
      }
      .type1 {
        height: 79px;
        background: url('@ass/img/1.0.0/img_sy_sjbg01.png') no-repeat;
      }
      .type2 {
        height: 79px;
        margin: 20px 0;
        background: url('@ass/img/1.0.0/img_sy_sjbg02.png') no-repeat;
      }
      .type3 {
        box-sizing: border-box;
        padding-top: 10px;
        height: 223px;
        background: url('@ass/img/1.0.0/img_sy_clfxbg.png') no-repeat;
        .ty3_number {
          font-size: 14px;
          color: #ffffff;
          display: flex;
          align-items: center;
        }
        .flex_clumn_list {
          margin-top: 30px;
        }
      }
    }
  }
  .bottom {
    width: 100%;
    height: 260px;
    background: url('@ass/img/1.0.0/img_sy_dbbg.png') no-repeat;
    background-position: 100% 100%;
    background-size: 100%;
    margin-top: 44px;
    position: relative;
    z-index: 3;
    .bottom_list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px 0 31px;
      .bottom_item {
        position: relative;
        width: 308px;
        height: 220px;
        .b_title {
          position: absolute;
          top: -11px;
          left: 50%;
          transform: translateX(-61%);
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
        }
        .b_title:last-child {
          left: 49%;
          transform: translateX(-55%);
        }
      }
      .bg1 {
        background: url('@ass/img/1.0.0/img_sydb01.svg') no-repeat;
        background-size: 308px 100%;
      }
      .bg2 {
        background: url('@ass/img/1.0.0/img_sydb02.svg') no-repeat;
        background-size: 308px 100%;
      }
      .bg3 {
        background: url('@ass/img/1.0.0/img_sydb03.svg') no-repeat;
        background-size: 308px 100%;
      }
      .bg4 {
        background: url('@ass/img/1.0.0/img_sydb04.svg') no-repeat;
        background-size: 308px 100%;
      }
      .bg5 {
        background: url('@ass/img/1.0.0/img_sydb05.svg') no-repeat;
        background-size: 308px 100%;
      }
      .bg1,
      .bg2,
      .bg3,
      .bg4,
      .bg5 {
        background-size: contain;
      }
      .bottom_item:first-child {
        margin-left: 0px;
      }
    }
  }
}
</style>
