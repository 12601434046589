
<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-12 10:48:48
 * @LastEditTime: 2022-04-26 11:26:57
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \networksecurity\src\App.vue
-->
<template>
  <div id="app">
    <template>
      <div :class="$route.name != 'login' ? 'appindex' : ''" ref="appRef">
        <div class="title" id="pageTitle" v-if="$route.name != 'login'">
          <div
            class="nav left"
            :class="{ selected: $route.name == 'securityoperationCenter' }"
          >
            <div
              class="text"
              @click="
                $router.push(
                  { path: '/securityoperationCenter' },
                  (onComplete) => {},
                  (onAbort) => {}
                )
              "
            >
              运营中心
            </div>
          </div>
          <div
            class="nav left"
            :class="{ selected: $route.name == 'powersupplyService' }"
          >
            <div
              class="text"
              @click="
                $router.push(
                  { path: '/powersupplyService' },
                  (onComplete) => {},
                  (onAbort) => {}
                )
              "
            >
              供电服务指挥
            </div>
          </div>
          <div
            class="nav left"
            :class="{ selected: $route.name == 'northeastData' }"
          >
            <div
              class="text"
              @click="
                $router.push(
                  { path: '/northeastData' },
                  (onComplete) => {},
                  (onAbort) => {}
                )
              "
            >
              东北能源大数据中心
            </div>
          </div>
          <div
            class="center cp"
            @click="
              $router.push(
                { path: '/' },
                (onComplete) => {},
                (onAbort) => {}
              )
            "
          >
            品阔网络安全综合防护平台
          </div>
          <div
            class="nav right"
            :class="{ selected: $route.name == 'safetyProtection' }"
          >
            <div
              class="text"
              @click="
                $router.push(
                  { path: '/safetyProtection' },
                  (onComplete) => {},
                  (onAbort) => {}
                )
              "
            >
              用采安全防护
            </div>
          </div>
          <div
            class="nav right"
            :class="{ selected: $route.name == 'smartEnergy' }"
          >
            <div
              class="text"
              @click="
                $router.push(
                  { path: '/smartEnergy' },
                  (onComplete) => {},
                  (onAbort) => {}
                )
              "
            >
              智慧能源调控
            </div>
          </div>
          <div
            class="nav right"
            :class="{ selected: $route.name == 'marketingData' }"
          >
            <div
              class="text"
              @click="
                $router.push(
                  { path: '/marketingData' },
                  (onComplete) => {},
                  (onAbort) => {}
                )
              "
            >
              营销数据脱敏
            </div>
          </div>
          <!-- <div class="header_decorate">
            <dv-decoration-5
              style="width: 600px; height: 40px"
              :color="['#1f8daf', '#1f8daf']"
            />
          </div> -->
        </div>
        <div
          class="main"
          :style="$route.path == '/login' ? 'top:0' : ' top: 115px;'"
        >
          <router-view />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import drawMixin from '@/utils/drawMixin'

export default {
  name: 'App',

  mixins: [drawMixin],

  methods: {
    // 提示一些信息
    prompt(msg) {
      let option = {
        type: 'error',
        showClose: true,
        customClass: 'el-prompt',
      }
      if (typeof msg === 'object') {
        option = _.assign({}, option, msg, { message: msg.msg || msg.message })
      } else {
        option.message = msg
      }
      if (this._message) {
        this._message.close()
        this._message = null
      }
      this._message = this.$message(option)
    },
  },

  // mounted() {
  //   this.cancelLoading()
  // },

  // data() {
  //   return {
  //     loading: true,
  //   }
  // },

  // // 20 * (window.innerHeight / 1080) + 'px'
  // beforeCreate() {
  //   document.documentElement.style.fontSize = 20 + 'px'
  // },

  // methods: {
  //   cancelLoading() {
  //     setTimeout(() => {
  //       this.loading = false
  //     }, 500)
  //   },
  // },
}
</script>

<style lang="scss">
body {
  background: url('https://f.dingdingkaike.com.cn/networksecurity/img_yeztbg6.png')
    no-repeat center;
  background-size: 100% 100%;
}
canvas{
  cursor: initial !important;
}
#app {
  font-family: 'Microsoft YaHei', 微软雅黑;
  // width: 1920px;
  // height: 1080px;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  // background: #0c2136 url('@/assets/img/1.0.0/bg.gif');
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  color: #fff;
  .appindex {
    color: #d3d6dd;
    width: 1920px;
    height: 1080px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: left top;
    overflow: hidden;
  }
  .title {
    z-index: 2;
    width: 1492px;
    height: 66px;
    text-align: center;
    position: relative;
    text-align: center;
    margin: 0 auto;
    line-height: 38px;
    display: flex;
    background: url('https://f.dingdingkaike.com.cn/networksecurity/img_btbg.png')
      no-repeat center;
    background-size: 100% 66px;
    .header_decorate {
      position: absolute;
      bottom: -32px;
      left: 50%;
      transform: translateX(-50%);
    }
    .nav {
      width: 175px;
      height: 38px;
      cursor: pointer;
      margin-top: 9px;
      text-align: center;
      border-radius: 4px;
      box-sizing: border-box;
      transition: background 0.25s;
      background: rgb(34, 108, 152, 0.2);
      &:hover {
        background: rgb(34, 108, 152, 0.4);
      }
      .text {
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
      }
      &.left {
        width: 175px;
        padding-left: 5px;
        margin-right: 8px;
        transform: skewX(15deg);
        .text {
          transform: skewX(-15deg);
        }
      }
      &.right {
        padding-right: 5px;
        margin-left: 8px;
        transform: skewX(-15deg);
        .text {
          transform: skewX(15deg);
        }
      }
    }
    .center {
      margin-top: 9px;
      width: 630px;
      font-size: 28px;
      font-weight: bold;
      color: #ffffff;
    }
  }
  .main {
    width: 100%;
    height: 100%;
    left: 0;
    position: absolute;
  }
  .selected {
    background-color: #a79030 !important;
  }
}
</style>
