<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-14 18:01:07
 * @LastEditTime: 2022-05-05 17:08:30
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \networksecurity\src\views\leftProduct\securityChip.vue
-->

<template>
  <div id="securityChip">
    <div class="item ml20 mr20">
      <div class="lp_title">安全芯片</div>
      <div class="basics" v-if="list.length > 0">
        <div class="list">
          <div class="l_item">
            <div class="tu">
              <img src="@ass/img/1.0.0/icon_4+4_yyxp.svg" alt="" />
            </div>
            <div class="right">
              <div class="number">
                <animate-number
                  from="0"
                  :to="list[0].value"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </div>
              <div class="name">{{ list[0].tip }}</div>
            </div>
          </div>
          <div class="l_item">
            <div class="tu">
              <img src="@ass/img/1.0.0/icon_4+4_yylx.svg" alt="" />
            </div>
            <div class="right">
              <div class="number">
                <animate-number
                  from="0"
                  :to="list[1].value"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </div>
              <div class="name">{{ list[1].tip }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="contain mt56">
        <div class="tu">
          <img src="@ass/img/1.0.0/img_4+4_aqxpjg.svg" alt="" />
        </div>
        <div class="icons">
          <div class="i_item mr51">
            <div class="i_tu">
              <img src="@ass/img/1.0.0/icon_4+4_dyjc.svg" alt="" />
            </div>
            <div class="i_name">电压监测</div>
          </div>

          <div class="i_item mr36">
            <div class="i_tu">
              <img src="@ass/img/1.0.0/icon_4+4_pljc.svg" alt="" />
            </div>
            <div class="i_name">频率监测</div>
          </div>
        </div>
        <div class="icons">
          <div class="i_item mr51">
            <div class="i_tu">
              <img src="@ass/img/1.0.0/icon_4+4_txmk.svg" alt="" />
            </div>
            <div class="i_name">通信模块</div>
          </div>

          <div class="i_item mr36">
            <div class="i_tu">
              <img src="@ass/img/1.0.0/icon_4+4_nbsz.svg" alt="" />
            </div>
            <div class="i_name">内部时钟</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'securityChip',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss" src="./index.scss" scoped>
</style>

<style lang="scss" scoped>
#securityChip {
  .mt50 {
    margin-top: 50px;
  }
  .mr36 {
    margin-right: 36px;
  }
  .mr51 {
    margin-right: 51px;
  }
  .mt56 {
    margin-top: 56px;
  }
  .contain {
    .tu {
      display: flex;
      justify-content: center;
      img {
        width: 289px;
      }
    }
  }
  .icons {
    display: flex;
    align-content: center;
    justify-content: flex-end;
    margin-top: 16px;
    .i_item {
      display: flex;
      align-items: center;
      .i_tu {
        width: 23px;
        margin-right: 12px;
      }
      .i_name {
        font-size: 11.6px;
        color: #b3f2f7;
        line-height: 20px;
      }
    }
  }
}
</style>