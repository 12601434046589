<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-14 10:48:04
 * @LastEditTime: 2022-06-14 18:24:56
 * @LastEditors: cyy
 * @Description: 安全运营中心
 * @FilePath: \networksecurity\src\views\securityoperationCenter\index.vue
-->
<template>
  <div class="securityoperationCenter">
    <earthModel style="padding-left: 100px"></earthModel>
    <div class="center">
      <div class="left main-left-nav">
        <div class="mr40">
          <div class="bottom_list mb37">
            <div class="bottom_item bg1">
              <div class="b_title">资产风险等级</div>
              <distribution :list="arrlist.zcfxdj"></distribution>
            </div>
          </div>
          <div class="bottom_list">
            <div class="bottom_item bg1">
              <div class="b_title">横向威胁态势</div>
              <div class="d-flex jc-center body-box">
                <dv-scroll-board
                  :config="config"
                  style="width: 290px; height: 250px"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="data">
          <div class="cp" @click="open('http://10.160.180.55:18080/')">
            <div class="data_name">
              <div class="fs1">综合安全态势</div>
            </div>
            <div class="item type1">
              <div class="date_title">
                <span class="left_name">
                  {{ datalist.terminal_risk.tip }} /
                </span>
                <span class="right_name">{{ datalist.terminals_sum.tip }}</span>
              </div>
              <div class="number">
                <span class="left_number" v-if="num == 0">
                  <animate-number
                    from="0"
                    :to="datalist.terminal_risk.value"
                    delay="2000"
                    easing="easeOutQuad"
                    style="display: block"
                  ></animate-number>
                </span>
                <span class="left_number" v-else>
                  {{ datalist.terminal_risk.value }}
                </span>
                <span class="right_number">
                  <span style="margin-left: 5px">/</span>
                  <animate-number
                    from="0"
                    :to="datalist.terminals_sum.value"
                    delay="2000"
                    easing="easeOutQuad"
                    style="display: block"
                  ></animate-number>
                </span>
              </div>
            </div>
            <div class="item type2">
              <div class="date_title">
                <span class="left_name">{{ datalist.server_risk.tip }} /</span>
                <span class="right_name">{{ datalist.server_sum.tip }}</span>
              </div>
              <div class="number">
                <span class="left_number" v-if="num == 0">
                  <animate-number
                    from="0"
                    :to="datalist.server_risk.value"
                    delay="2000"
                    easing="easeOutQuad"
                    style="display: block"
                  ></animate-number>
                </span>
                <span class="left_number" v-else>
                  {{ datalist.server_risk.value }}
                </span>
                <span class="right_number">
                  <span style="margin-left: 5px">/</span>
                  <animate-number
                    from="0"
                    :to="datalist.server_sum.value"
                    delay="2000"
                    easing="easeOutQuad"
                    style="display: block"
                  ></animate-number>
                </span>
              </div>
            </div>
          </div>
          <div
            class="type3 cp"
            @click="open('http://10.160.180.15:28088/Portal')"
          >
            <div class="fs1 _center">策略优化分析</div>
            <div class="flex_clumn_list">
              <div class="clumn_list_item">
                <div class="top_title">{{ datalist.strategy_rong.tip }}</div>
                <div class="ty3_number">
                  <span class="numfamily mr5" v-if="num == 0">
                    <animate-number
                      from="0"
                      :to="datalist.strategy_rong.value"
                      delay="2000"
                      easing="easeOutQuad"
                      style="display: block"
                    ></animate-number>
                  </span>
                  <span class="numfamily mr5" v-else>
                    {{ datalist.strategy_rong.value }}
                  </span>
                  条
                </div>
              </div>
              <div class="clumn_list_item">
                <div class="top_title">{{ datalist.strategy_cover.tip }}</div>
                <div class="ty3_number">
                  <span class="numfamily mr5" v-if="num == 0">
                    <animate-number
                      from="0"
                      :to="datalist.strategy_cover.value"
                      delay="2000"
                      easing="easeOutQuad"
                      style="display: block"
                    ></animate-number>
                  </span>
                  <span class="numfamily mr5" v-else>
                    {{ datalist.strategy_cover.value }}
                  </span>
                  条
                </div>
              </div>
              <div class="clumn_list_item">
                <div class="top_title">{{ datalist.strategy_merge.tip }}</div>
                <div class="ty3_number">
                  <span class="numfamily mr5">
                    <animate-number
                      from="0"
                      :to="datalist.strategy_merge.value"
                      delay="2000"
                      easing="easeOutQuad"
                      style="display: block"
                    ></animate-number>
                  </span>
                  条
                </div>
              </div>
            </div>
            <div class="flex_clumn_list">
              <div class="clumn_list_item">
                <div class="top_title">{{ datalist.strategy_just.tip }}</div>
                <div class="ty3_number">
                  <span class="numfamily mr5">
                    <animate-number
                      from="0"
                      :to="datalist.strategy_just.value"
                      delay="2000"
                      easing="easeOutQuad"
                      style="display: block"
                    ></animate-number>
                  </span>
                  条
                </div>
              </div>
              <div class="clumn_list_item">
                <div class="top_title">{{ datalist.strategy_back.tip }}</div>
                <div class="ty3_number">
                  <span class="numfamily mr5">
                    <animate-number
                      from="0"
                      :to="datalist.strategy_back.value"
                      delay="2000"
                      easing="easeOutQuad"
                      style="display: block"
                    ></animate-number>
                  </span>
                  条
                </div>
              </div>
              <div class="clumn_list_item">
                <div class="top_title">
                  {{ datalist.strategy_conflict.tip }}
                </div>
                <div class="ty3_number">
                  <span class="numfamily mr5">
                    <animate-number
                      from="0"
                      :to="datalist.strategy_conflict.value"
                      delay="2000"
                      easing="easeOutQuad"
                      style="display: block"
                    ></animate-number>
                  </span>
                  条
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="name cp" @click="open('http://10.160.180.15:28088/Portal')">
        <div class="security_center">运营中心</div>
      </div>
      <div class="right main-right-nav">
        <div
          class="Security_situation cp"
          @click="open('http://10.160.180.15:28088/Portal')"
        >
          <div class="fs1 _center" style="margin-bottom: 66px">
            安全事件态势
          </div>
          <div class="flex_clumn_list mb70">
            <div class="clumn_list_item">
              <div class="top_title">{{ datalist.spyware.tip }}</div>
              <div class="ty3_number numfamily" style="color: #c55cff">
                <animate-number
                  from="0"
                  :to="datalist.spyware.value"
                  delay="2000"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </div>
            </div>
            <div class="clumn_list_item">
              <div class="top_title">{{ datalist.trojan_horse.tip }}</div>
              <div class="ty3_number numfamily" style="color: #a9cc32">
                <animate-number
                  from="0"
                  :to="datalist.trojan_horse.value"
                  delay="2000"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </div>
            </div>
          </div>
          <div class="flex_clumn_list mb70">
            <div class="clumn_list_item">
              <div class="top_title">{{ datalist.ransomware.tip }}</div>
              <div
                class="ty3_number numfamily"
                style="color: #525cff"
                v-if="num == 0"
              >
                <animate-number
                  from="0"
                  :to="datalist.ransomware.value"
                  delay="2000"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </div>
              <div class="ty3_number numfamily" style="color: #525cff" v-else>
                {{ datalist.ransomware.value }}
              </div>
            </div>
            <div class="clumn_list_item">
              <div class="top_title">{{ datalist.bitcoin_mining.tip }}</div>
              <div
                class="ty3_number numfamily"
                style="color: #fa9c3b"
                v-if="num == 0"
              >
                <animate-number
                  from="0"
                  :to="datalist.bitcoin_mining.value"
                  delay="2000"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </div>
              <div class="ty3_number numfamily" style="color: #fa9c3b" v-else>
                {{ datalist.bitcoin_mining.value }}
              </div>
            </div>
          </div>
          <div class="flex_clumn_list">
            <div class="clumn_list_item">
              <div class="top_title">{{ datalist.banking_trojan.tip }}</div>
              <div class="ty3_number numfamily" style="color: #49b7d3">
                <animate-number
                  from="0"
                  :to="datalist.banking_trojan.value"
                  delay="2000"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </div>
            </div>
            <div class="clumn_list_item">
              <div class="top_title">{{ datalist.downloader_trojan.tip }}</div>
              <div
                class="ty3_number numfamily"
                style="color: #e039a1"
                v-if="num == 0"
              >
                <animate-number
                  from="0"
                  :to="datalist.downloader_trojan.value"
                  delay="2000"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </div>
              <div class="ty3_number numfamily" style="color: #e039a1" v-else>
                {{ datalist.downloader_trojan.value }}
              </div>
            </div>
          </div>
        </div>
        <div class="list">
          <div class="bottom_list mb37">
            <div class="bottom_item bg1">
              <div class="b_title">攻击者态势</div>
              <pie height="250px" :acquiredOption="attacker"></pie>
            </div>
          </div>
          <div class="bottom_list">
            <div class="bottom_item bg1">
              <div class="b_title">外连风险态势</div>
              <risk :chartlist="chartlist"></risk>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="b_title">横向威胁态势</div>
      <!-- @click="open('http://11.174.4.1')" -->
      <div class="bottom_contain w260">
        <div class="b_c_title fs1">威胁情报库</div>
        <div class="fs18 colorw _center">威胁总条数</div>
        <div class="number">
          <animate-number
            from="0"
            :to="percentagetotol"
            easing="easeOutQuad"
            style="display: block"
          ></animate-number>
        </div>
        <div v-if="percentage.length > 0">
          <div
            class="press_contain flex-center"
            v-for="item in percentage"
            :key="item.name"
          >
            <span class="sp1">{{ item.tip }}</span>
            <el-progress
              :percentage="Number(item.value) > 100 ? 100 : Number(item.value)"
              :format="format(item.value)"
            ></el-progress>
          </div>
        </div>
      </div>
      <div>
        <dv-decoration-2 :reverse="true" style="width: 5px; height: 351px" />
      </div>

      <div
        class="bottom_contain w302 cp"
        @click="open('http://10.160.180.15:28088/Portal')"
      >
        <div class="b_c_title fs1">联动处置</div>
        <pie :acquiredOption="Attackerposture" height="120px"></pie>
        <div style="margin-left: 56px" class="mt20 flex-center">
          <dv-decoration-9 style="width: 92px; height: 92px">
            {{ Closed_loop_disposal.value }}%
          </dv-decoration-9>
          <span class="fs14 ml30">闭环处置(率)</span>
        </div>
      </div>
      <div>
        <dv-decoration-2 :reverse="true" style="width: 5px; height: 351px" />
      </div>
      <div
        class="bottom_contain w181 cp"
        @click="open('http://10.160.180.15:28088/Portal')"
      >
        <div class="b_c_title fs1">配置核查</div>
        <pie :acquiredOption="configure" height="200px"></pie>
      </div>
      <div>
        <dv-decoration-2 :reverse="true" style="width: 5px; height: 351px" />
      </div>
      <div
        class="bottom_contain w275 cp"
        @click="open('http://10.160.180.15:28088/Portal')"
      >
        <div class="b_c_title fs1">溯源取证</div>
        <div class="d-flex jc-center body-box">
          <dv-scroll-board
            :config="config2"
            style="width: 270px; height: 200px"
          />
        </div>
      </div>
      <div>
        <dv-decoration-2 :reverse="true" style="width: 5px; height: 351px" />
      </div>
      <div
        class="bottom_contain w275 cp"
        @click="open('http://10.160.180.15:28088/Portal')"
      >
        <div class="b_c_title fs1">资产管理</div>
        <div v-if="percentage2.length > 0">
          <div
            class="press_contain2 flex-center"
            v-for="item2 in percentage2"
            :key="item2.asset_retirement"
          >
            <span class="sp1">{{ item2.tip }}</span>
            <el-progress
              :percentage="
                Number(item2.value) > 100 ? 100 : Number(item2.value)
              "
              :format="format(item2.value)"
            ></el-progress>
          </div>
        </div>
      </div>
      <div>
        <dv-decoration-2 :reverse="true" style="width: 5px; height: 351px" />
      </div>
      <div
        class="bottom_contain w218 cp"
        @click="open('http://10.160.3.94:18080/portal/')"
      >
        <div class="b_c_title fs1">安全审计</div>
        <pie :acquiredOption="audit" height="200px"></pie>
      </div>
      <div>
        <dv-decoration-2 :reverse="true" style="width: 5px; height: 351px" />
      </div>
      <div
        class="bottom_contain w278 cp"
        @click="open('http://10.160.180.15:28088/Portal')"
      >
        <div class="b_c_title fs1">数据跨域访问</div>
        <data-crosseomain :optionarr="optiondata"></data-crosseomain>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import risk from './components/risk.vue'
import distribution from './components/distribution.vue'
import dataCrosseomain from './components/dataCrosseomain.vue'
import pie from '@cm/charts/pie'
export default {
  name: 'securityoperationCenter',

  components: {
    pie,
    dataCrosseomain,
    distribution,
    risk,
  },

  data() {
    return {
      num: 0,

      percentagetotol: 0,

      configure: {},

      optiondata: [],

      attacker: {},

      Closed_loop_disposal: '', //闭环处置

      datalist: new Proxy(
        {},
        {
          get() {
            return {}
          },
        }
      ),
      chartlist: [],
      formlist: [],
      form2list: [],
      arrlist: {},
      audit: {},

      Attackerposture: {},

      percentage: [],

      percentage2: [],

      config: {
        header: ['IP', '类别', '攻击次数'],
        data: [],
        rowNum: 7, //表格行数
        headerHeight: 35,
        headerBGC: '#0F263D', //表头
        oddRowBGC: '#0F263D', //奇数行
        evenRowBGC: '#0F263D', //偶数行
        columnWidth: [120],
        align: ['center'],
      },

      config2: {
        header: ['攻击源IP', '级别'],
        data: [],
        rowNum: 5, //表格行数
        headerHeight: 35,
        headerBGC: '#0F263D', //表头
        oddRowBGC: '#0F263D', //奇数行
        evenRowBGC: '#0F263D', //偶数行
        columnWidth: [150],
        align: ['center'],
      },
    }
  },

  created() {
    this.getsecurityoperationCenter()
  },

  filters: {
    getlv(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '<span style="color:#37C58F;">低</span> '
          break
        case 2:
          text = '<span style="color:#DF632A;">中</span> '
          break
        case 3:
          text = '<span style="color:#FC3737;">高</span> '

          break
      }
      return text
    },
  },

  destroyed() {
    clearInterval(this.timer)
  },

  methods: {
    open(url) {
      window.open(url)
    },
    format(percentage) {
      return () => {
        return percentage
      }
    },

    async settimer() {
      this.timer = setInterval(async () => {
        this.num += 1
        await this.getsecurityoperationCenter()
      }, 15000)
    },

    async getsecurityoperationCenter() {
      try {
        const { chart, list, form, form2, arr, bar } = await this.$http.get(
          '/index/securityoperationCenter',
          {
            num: this.num,
          }
        )
        this.datalist = list
        this.chartlist = chart
        this.formlist = form
        this.form2list = form2
        this.arrlist = arr
        this.configlist(this.formlist)
        this.config2list(this.form2list)
        this.getaudit(this.arrlist.aqsj)
        this.getconfigure(this.arrlist.pzhc)
        this.Closed_loop_disposal = this.arrlist.ldcz.shift()
        this.getAttackerposture(this.arrlist.ldcz)
        this.getattacker(this.arrlist.gjzst)
        this.percentage2 = this.arrlist.zcgl
        this.optiondata = bar
        const maxval = this.arrlist.wxqbk.pop().value
        this.percentagetotol = maxval
        this.percentage = this.arrlist.wxqbk
        if (this.num == 0) {
          setTimeout(() => {
            this.settimer()
          }, 3000)
        }
      } catch (e) {
        console.log(e)
      }
    },

    getattacker(list) {
      this.attacker = {
        data: list.map((item) => {
          let color = item.name == 'attacker_web' ? '#4BE297' : '#F4D00D'
          return {
            value: item.value,
            name: item.tip,
            itemStyle: {
              color: color,
            },
          }
        }),
      }
    },

    configlist(val) {
      let data = val.map((item) => {
        return [item.ip, item.type, item.num]
      })
      this.config = Object.assign({}, this.config, {
        data,
      })
    },

    config2list(val) {
      let data = val.map((item) => {
        return [item.ip, this.$options.filters['getlv'](item.lv)]
      })
      this.config2 = Object.assign({}, this.config2, {
        data,
      })
    },

    getaudit(list) {
      let val = list.map((item) => {
        let colorval =
          item.name == 'ultra_vires'
            ? '#ED576E'
            : item.name == 'violation'
            ? '#DECA04'
            : '#3761E0'
        return {
          value: item.value,
          name: item.tip,
          itemStyle: {
            color: colorval,
          },
        }
      })

      this.audit = {
        legend: {
          bottom: '0%',
          itemWidth: 12,
          itemHeight: 12,
          textStyle: {
            color: '#fff',
          },
        },
        title: {
          left: 'center',
          text: '安全行为占比',
          show: true,
          textStyle: {
            color: '#fff',
          },
        },

        series: [
          {
            width: '112',
            type: 'pie',
            left: '57px',
            bottom: '20px',
            avoidLabelOverlap: false,

            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold',
              },
            },

            label: {
              show: false,
            },

            data: val,
          },
        ],
      }
    },
    getconfigure(list) {
      let val = list.map((item) => {
        let colorval =
          item.name == 'IDS'
            ? '#44C8EE'
            : item.name == 'IPS'
            ? '#ED576E'
            : item.name == 'WAF'
            ? '#DECA04'
            : '#5774FA'
        return {
          value: item.value,
          name: item.tip,
          itemStyle: {
            color: colorval,
          },
        }
      })

      this.configure = {
        legend: {
          bottom: '0%',
          itemWidth: 12,
          itemHeight: 12,
          textStyle: {
            color: '#fff',
          },
        },
        title: {
          left: 'center',
          text: '安全设备占比',
          show: true,
          textStyle: {
            color: '#fff',
          },
        },

        series: [
          {
            width: '112',
            type: 'pie',
            left: '34px',
            bottom: '20px',
            radius: ['60%', '80%'],
            avoidLabelOverlap: false,

            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold',
              },
            },

            label: {
              show: false,
            },

            data: val,
          },
        ],
      }
    },
    getAttackerposture(list) {
      let val = list.map((item) => {
        let colorval =
          item.name == 'pending_disposal'
            ? '#44C8EE'
            : item.name == 'automatic_disposal'
            ? '#DECA04'
            : '#ED576E'
        return {
          value: item.value,
          name: item.tip,
          itemStyle: {
            color: colorval,
          },
        }
      })

      let count = list
        .map((item) => item.value)
        .reduce((sum, item) => {
          return Number(sum) + Number(item)
        })

      this.Attackerposture = {
        title: {
          itemGap: 8,
          text: '告警总数',
          subtext: count,
          left: '24%',
          top: '32%',
          textStyle: {
            fontSize: 12,
            color: '#fff',
          },
          subtextStyle: {
            fontSize: 20,
            color: '#FED01C',
          },
        },

        series: [
          {
            width: '300',
            left: '-50',
            type: 'pie',
            radius: ['60%', '80%'],
            avoidLabelOverlap: false,

            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold',
              },
            },

            label: {
              show: true,
              width: 106,
              alignTo: 'labelLine',
              formatter: '{time|{c}}',
              distanceToLabelLine: 0,
              rich: {
                time: {
                  fontSize: 10,
                  color: '#fff',
                },
              },
            },
            labelLine: {
              length: 7,
              length2: 11,
              show: true,
            },

            data: val,
          },
        ],
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.securityoperationCenter {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  // background: url('@ass/img/1.0.0/img_sy_ztt.png') no-repeat;
  // background-size: 864px 538px;
  // background-position: center 36px;
  line-height: 1.5;
  ::v-deep .el-progress-circle__track {
    stroke: #3e5163;
  }
  ::v-deep .el-progress__text {
    color: #fff !important;
  }
  .numfamily {
    font-family: 'num';
  }
  .mb70 {
    margin-bottom: 70px;
  }
  .w218 {
    width: 218px;
  }
  .w302 {
    width: 302px;
  }
  .w275 {
    width: 275px;
  }
  .w278 {
    width: 278px;
  }
  .w181 {
    width: 181px;
  }
  .mb37 {
    margin-bottom: 37px;
  }
  .w260 {
    width: 260px;
  }

  // 小标题的样式
  .fs1 {
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
  }

  // 上文字下数据的列表
  .flex_clumn_list {
    display: flex;
    align-items: center;
    justify-content: space-around;
    .clumn_list_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      .top_title {
        font-size: 14px;
        color: #ffffff;
        text-align: center;
      }
    }
  }
  img {
    width: auto;
    height: 100%;
  }
  .center {
    display: flex;
    position: relative;
    z-index: 3;
    .left {
      display: flex;
      align-items: center;
      width: 822px;
    }
    .name {
      width: 379px;
      flex-shrink: 0;
      // align-self: start;
      .security_center {
        height: 36px;
        margin-left: 0 !important;
        width: 220px;
        text-align: center;
        line-height: 36px;
        background: rgba(46, 75, 106, 0.38);
        border-radius: 0 0 4px 4px;
        border: 1px solid rgba(50, 228, 244, 0.28);
      }
    }
    .right {
      flex: 1;
      display: flex;
      align-items: center;
      .Security_situation {
        margin-left: 30px;
        margin-right: 40px;
        padding-top: 5px;
        box-sizing: border-box;
        background: url('@ass/img/1.0.0/img_sy_aqsjbg.png') no-repeat;
        width: 243px;
        height: 463px;
      }
      img {
        float: left !important;
      }
      .name {
        float: right !important;
        padding-right: 26px !important;
        padding-left: 0 !important;
        text-align: right;
      }
    }

    .data {
      width: 361px;
      .data_name {
        width: fit-content;
        height: 30px;
        margin-bottom: 10px;
        background: linear-gradient(
          270deg,
          rgba(47, 75, 147, 0.66) 0%,
          rgba(12, 32, 48, 0) 100%
        );
        border-radius: 1px;
      }
      .item {
        width: 360px;
        padding: 15px 0 0 40px;
        .date_title {
          font-size: 14px;
          margin-bottom: 10px;
          .left_name {
            color: #ffffff;
          }
          .right_name {
            color: #bababa;
          }
        }
        .number {
          display: flex;
          align-items: center;
          font-family: 'num';
          font-size: 26px;
          font-weight: bold;
          line-height: 26px;
          .left_number {
            color: #fed01c;
          }
          .right_number {
            color: #ffffff;
            display: flex;
            align-items: center;
          }
        }
      }
      .type1 {
        height: 79px;
        background: url('@ass/img/1.0.0/img_sy_sjbg01.png') no-repeat;
      }
      .type2 {
        height: 79px;
        margin: 20px 0;
        background: url('@ass/img/1.0.0/img_sy_sjbg02.png') no-repeat;
      }
      .type3 {
        box-sizing: border-box;
        padding-top: 10px;
        height: 223px;
        background: url('@ass/img/1.0.0/img_sy_clfxbg.png') no-repeat;
        .ty3_number {
          font-size: 14px;
          color: #ffffff;
          display: flex;
          align-items: center;
        }
        .flex_clumn_list {
          margin-top: 30px;
        }
      }
    }
  }
  .bottom_list {
    padding-bottom: 20px;
    .dv-scroll-board {
      width: 90%;
      margin: 30px 10px;
    }
    .b_title {
      position: absolute;
      top: -11px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
    }
    .bottom_item {
      position: relative;
      width: 308px;
      height: 253px;
    }
    .bg1 {
      background: url('@ass/img/1.0.0/icon_aqyyzx_nrbg.svg') no-repeat;
      background-size: contain;
    }

    .bg1 {
      background-size: contain;
    }
  }
  .b_title {
    position: absolute;
    top: -11px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
  }
  .bottom {
    height: 351px;
    margin: 0 auto;
    display: flex;
    z-index: 3;
    position: relative;
    background: url('@ass/img/1.0.0/icon_aqyyzx_nrbg3.svg') no-repeat;
    .bottom_contain {
      .number {
        font-size: 34px;
        font-weight: bold;
        color: #fed01c;
        margin: 10px 0;
        line-height: 34px;
        text-align: center;
      }
      ::v-deep .press_contain {
        margin-bottom: 17px;
        justify-content: center;
        .el-progress {
          width: 160px;
          margin-left: 10px;
          .el-progress__text {
            color: #fed01c;
          }
          .el-progress-bar__outer {
            background: transparent;
          }
          .el-progress-bar__inner {
            background: linear-gradient(
              90deg,
              rgba(254, 208, 28, 0) 0%,
              #fed01c 100%
            );
            border-radius: 5px;
          }
        }
        .sp1 {
          font-size: 14px;
          color: #ffffff;
          line-height: 19px;
        }
      }
      ::v-deep .press_contain2 {
        margin-bottom: 17px;
        justify-content: center;
        .el-progress {
          width: 180px;
          margin-left: 10px;
          .el-progress__text {
            color: #219299;
          }
          .el-progress-bar__outer {
            background: transparent;
          }
          .el-progress-bar__inner {
            background: linear-gradient(
              90deg,
              rgba(13, 35, 57, 0) 0%,
              #28bdbe 100%
            );
          }
        }
        .sp1 {
          font-size: 14px;
          color: #ffffff;
          line-height: 19px;
        }
      }
      .b_c_title {
        padding-top: 2px;
        box-sizing: border-box;
        text-align: center;
        width: 100px;
        height: 30px;
        background: linear-gradient(
          270deg,
          rgba(12, 32, 48, 0) 0%,
          rgba(47, 75, 147, 0.66) 100%
        );
        margin: 40px auto 24px;
        border-radius: 1px;
      }
    }
  }
}
</style>
