<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-20 11:02:15
 * @LastEditTime: 2022-04-27 18:57:08
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \networksecurity\src\views\securityoperationCenter\components\dataCrosseomain.vue
-->
<template>
  <div ref="dataCrossEomain" style="width: 270px; height: 250px"></div>
</template>

<script>
export default {
  mounted() {
    this.getdataCrossdomain()
  },

  data() {
    return {}
  },

  watch: {
    optionarr: {
      handler(val) {
        if (val.length > 0) {
          this.getdataCrossdomain()
        }
      },
      immediate: true,
    },
  },

  props: {
    optionarr: [Array],
  },

  methods: {
    getdataCrossdomain() {
      let myChart = this.$echarts.getInstanceByDom(this.$refs.dataCrossEomain)

      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.dataCrossEomain)
      }

      let option = {
        color: ['#003366', '#006699', '#4cabce', '#e5323e'],
        dataset: {
          source: this.optionarr,
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          textStyle: {
            color: '#fff',
          },
          orient: 'vertical',
          bottom: '4%',
          itemWidth: 12,
        },

        xAxis: {
          type: 'category',
          data: [
            {
              value: '最大并发',
              textStyle: {
                color: '#fff',
              },
            },
            {
              value: '通道时延',
              textStyle: {
                color: '#fff',
              },
            },
            {
              value: '丢包率',
              textStyle: {
                color: '#fff',
              },
            },
          ],
          axisTick: {
            show: false,
          },
        },
        grid: {
          top: '10',
          left: '50',
          height: '40%',
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              // type: 'dashed',
              color: '#293A5F',
            },
          },
        },
        series: [
          {
            type: 'bar',
            seriesLayoutBy: 'row',
            itemStyle: {
              color: '#44C8EE',
            },
          },
          {
            type: 'bar',
            seriesLayoutBy: 'row',
            itemStyle: {
              color: '#ED576E',
            },
          },
          {
            type: 'bar',
            seriesLayoutBy: 'row',
            itemStyle: {
              color: '#DECA04',
            },
          },
          {
            type: 'bar',
            seriesLayoutBy: 'row',
            itemStyle: {
              color: '#5774FA',
            },
          },
        ],
      }
      myChart.setOption(option)
    },
  },
}
</script>

<style>
</style>