<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-22 16:47:52
 * @LastEditTime: 2022-06-10 10:17:14
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \networksecurity\src\views\smartEnergy\index.vue
-->

<template>
  <div id="smartEnergy">
    <div class="left">
      <div class="operation">
        <div class="b_title">操作系统安全</div>
        <div class="item_list" v-if="czxtaq.length > 0">
          <div class="item">
            <div class="name">{{ czxtaq[0].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="czxtaq[0].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              次
            </div>
          </div>
          <div class="item">
            <div class="name">{{ czxtaq[1].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="czxtaq[1].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              次
            </div>
          </div>
          <div class="item">
            <div class="name">{{ czxtaq[2].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="czxtaq[2].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              个
            </div>
          </div>
          <div class="item">
            <div class="name">{{ czxtaq[3].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="czxtaq[3].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              次
            </div>
          </div>
          <div class="item">
            <div class="name">{{ czxtaq[4].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="czxtaq[4].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              %
            </div>
          </div>
          <div class="item">
            <div class="name">{{ czxtaq[5].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="czxtaq[5].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              次
            </div>
          </div>
          <div class="item">
            <div class="name">{{ czxtaq[6].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="czxtaq[6].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              次
            </div>
          </div>
        </div>
      </div>
      <div class="database">
        <div class="b_title">数据库系统安全</div>
        <div class="item_list" v-if="sjkaq.length > 0">
          <div class="item">
            <div class="name">{{ sjkaq[1].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="sjkaq[1].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              次
            </div>
          </div>
          <div class="item">
            <div class="name">{{ sjkaq[0].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="sjkaq[0].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              次
            </div>
          </div>
          <div class="item">
            <div class="name">{{ sjkaq[2].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="sjkaq[2].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              次
            </div>
          </div>
          <div class="item">
            <div class="name">{{ sjkaq[3].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="sjkaq[3].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              次
            </div>
          </div>
        </div>
      </div>
      <div class="safety_monitoring">
        <div class="b_title">安全监控</div>
        <dv-scroll-board
          :config="config2"
          style="
            width: 97%;
            height: 200px;
            padding-top: 30px;
            box-sizing: border-box;
          "
          class="pl10"
        />
      </div>
    </div>
    <div class="center">
      <div class="c_top cp" @click="open('http://10.160.180.15:28088/Portal')">
        <img src="@ass/img/1.0.0/img_zhnytkztt.svg" alt="" />
        <div class="groundlist" v-if="zngk.length > 0">
          <div class="flex-center">
            <div class="number_group" style="margin-right: 168px">
              <div class="font1">{{ zngk[0].tip }}</div>
              <div class="number">
                <animate-number
                  from="0"
                  :to="zngk[0].value"
                  delay="2000"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
                <div class="font1" style="margin-left: 3px">起</div>
              </div>
            </div>
            <div class="number_group">
              <div class="font1">{{ zngk[1].tip }}</div>
              <div class="number">
                <animate-number
                  from="0"
                  :to="zngk[1].value"
                  delay="2000"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
                <div class="font1" style="margin-left: 3px">个</div>
              </div>
            </div>
          </div>
          <div class="flex-center" style="margin-top: 28px">
            <div
              class="number_group"
              style="margin-right: 198px; margin-left: 18px"
            >
              <div class="font1">{{ zngk[2].tip }}</div>
              <div class="number">
                <animate-number
                  from="0"
                  :to="zngk[2].value"
                  delay="2000"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
                <div class="font1" style="margin-left: 3px">家</div>
              </div>
            </div>
            <div class="number_group">
              <div class="font1">{{ zngk[3].tip }}</div>
              <div class="number">
                <animate-number
                  from="0"
                  :to="zngk[3].value"
                  delay="2000"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
                <div class="font1" style="margin-left: 3px">座</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c_bottom">
        <div class="b_title">攻击监测</div>
        <dv-scroll-board
          :config="config"
          style="
            width: 97%;
            height: 150px;
            padding-top: 30px;
            box-sizing: border-box;
          "
          class="pl10"
        />
      </div>
    </div>
    <div class="right">
      <div class="data_security">
        <div class="b_title">数据安全</div>
        <div style="padding-top: 40px" class="d-flex">
          <pie :acquiredOption="audit" width="160px" height="200px"></pie>
          <data-usage :dataUsagelist="dataUsagelist"></data-usage>
        </div>
      </div>
      <div class="host_protection">
        <div class="b_title">主机防护</div>
        <div class="item_list" v-if="zjfh.length > 0">
          <div class="item">
            <div class="name">{{ zjfh[0].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="zjfh[0].value"
                duration="5000"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              %
            </div>
          </div>
          <div class="item">
            <div class="name">{{ zjfh[1].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="zjfh[1].value"
                duration="5000"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              %
            </div>
          </div>
          <div class="item">
            <div class="name">{{ zjfh[2].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="zjfh[2].value"
                duration="5000"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              %
            </div>
          </div>
          <div class="item">
            <div class="name">{{ zjfh[3].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="zjfh[3].value"
                duration="5000"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              个
            </div>
          </div>
        </div>
      </div>
      <div class="system_protection">
        <div class="b_title">系统防护</div>
        <div class="item_list" style="padding-top: 65px" v-if="xtfh.length > 0">
          <div class="item">
            <div class="name">{{ xtfh[0].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="xtfh[0].value"
                duration="5000"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              个
            </div>
          </div>
          <div class="item">
            <div class="name">{{ xtfh[1].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="xtfh[1].value"
                duration="5000"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              个
            </div>
          </div>
          <div class="item">
            <div class="name">{{ xtfh[2].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="xtfh[2].value"
                duration="5000"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              次
            </div>
          </div>
          <div class="item">
            <div class="name">{{ xtfh[3].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="xtfh[3].value"
                duration="5000"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              次
            </div>
          </div>
          <div class="item">
            <div class="name">{{ xtfh[4].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="xtfh[4].value"
                duration="5000"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              %
            </div>
          </div>
          <div class="item">
            <div class="name">{{ xtfh[5].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="xtfh[5].value"
                duration="5000"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              次
            </div>
          </div>
          <div class="item">
            <div class="name">{{ xtfh[6].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="xtfh[6].value"
                duration="5000"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              次
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataUsage from './components/dataUsage.vue'
import pie from '@cm/charts/pie'
export default {
  components: {
    pie,
    dataUsage,
  },

  data() {
    return {
      dataUsagelist: [],
      form: [],
      form2: [],
      xtfh: [],
      sjaq: [],
      sjkaq: [],
      zjfh: [],
      zngk: [],
      czxtaq: [],
      audit: {},
      config2: {
        header: ['时间', '协议类型', '报文数', '流量字节'],
        data: [],
        rowNum: 4, //表格行数
        headerHeight: 40,
        headerBGC: '#0F263D', //表头
        oddRowBGC: '#0F263D', //奇数行
        evenRowBGC: '#0F263D', //偶数行
        columnWidth: [100],
        align: ['center'],
      },
      config: {
        header: [
          '时间',
          '攻击源',
          '攻击目标',
          '攻击源位置',
          '攻击方式',
          '外置措施',
        ],
        data: [],
        rowNum: 2, //表格行数
        headerHeight: 40,
        columnWidth: [180, 156, 280, 156, 156, 156],
        headerBGC: '#0F263D', //表头
        oddRowBGC: '#0F263D', //奇数行
        evenRowBGC: '#0F263D', //偶数行
        align: ['center'],
      },
    }
  },

  filters: {
    getcolor(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '#37C58F'
          break
        case 2:
          text = '#FFCB74'
          break
        case 3:
          text = '#FC3737'
          break
      }
      return text
    },
  },

  created() {
    this.getsmartEnergy()
  },

  watch: {
    sjaq: {
      handler(val) {
        if (val.length > 0) {
          this.getaudit()
        }
      },
    },
  },

  methods: {
    open(url) {
      window.open(url)
    },
    async getsmartEnergy() {
      const {
        form,
        form2,
        list: { czxtaq, sjaq, sjkaq, xtfh, zjfh, zngk },
      } = await this.$http.get('/index/smartEnergy')
      this.form = form
      this.form2 = form2
      this.xtfh = xtfh
      this.sjaq = sjaq
      this.sjkaq = sjkaq
      this.zjfh = zjfh
      this.zngk = zngk
      this.czxtaq = czxtaq
      this.getconfig()
      this.getconfig2()
      this.dataUsagelist = this.sjaq.slice(2, 5)
    },

    getconfig2() {
      let arr = this.form.map((item) => {
        return [
          `<span   title='${item.value}'>${item.value}</span>`,
          `<span  title = "${item.value1}">${item.value1}</span>`,
          `<span  title = "${item.value2}">${item.value2}</span>`,
          `<span  title = "${item.value3}">${item.value3}</span>`,
        ]
      })
      this.config2 = Object.assign({}, this.config2, { data: arr })
    },

    getconfig() {
      let arr = this.form2.map((item) => {
        return [
          item.value,
          `<span style="color:#FFCB74;" title='${item.value1}'>${item.value1}</span>`,
          `<span  title = "${item.value2}">${item.value2}</span>`,
          `${item.value3}`,
          `<span style="color:#FFCB74;">${item.value4}</span>`,
          `<span style="color:${this.$options.filters['getcolor'](
            item.status
          )};"">${item.value5}</span> `,
        ]
      })
      this.config = Object.assign({}, this.config, { data: arr })
    },

    getaudit() {
      const vallist = this.sjaq.slice(0, 2)
      let val = vallist.map((item) => {
        return {
          value: item.value,
          name: item.tip,
          itemStyle: {
            color: item.name == 'decryption_algorithm' ? '#44C8EE' : '#ED576E',
          },
        }
      })
      this.audit = {
        title: {
          left: 'center',
          text: '数据分布存储',
          show: true,
          textStyle: {
            color: '#fff',
          },
        },
        legend: {
          bottom: '15%',
          itemWidth: 12,
          itemHeight: 12,
          textStyle: {
            color: '#fff',
          },
          itemGap: 16,
        },

        series: [
          {
            width: '90',
            type: 'pie',
            left: '30px',
            bottom: '20px',
            radius: ['60%', '80%'],
            avoidLabelOverlap: false,

            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold',
              },
            },

            label: {
              show: false,
            },

            data: val,
          },
        ],
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#smartEnergy {
  padding: 0 20px;
  display: flex;
  .b_title {
    position: absolute;
    top: -11px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
  }
  .item_list {
    padding: 40px 15px 0 15px;
    .item {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .name {
        font-size: 14px;
        color: #ffffff;
        line-height: 14px;
      }
      .percent {
        width: 90px;
        height: 28px;
        background: rgba(34, 108, 152, 0.28);
        box-shadow: inset 0px 0px 6px 0px #0082f3;
        opacity: 0.86;
        border: 1px solid #0b808c;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        line-height: 16px;
      }
    }
  }
  .left {
    .operation {
      width: 360px;
      height: 400px;
      background: url('@ass/img/1.0.0/icon_zhnydk_nrbg1.svg') no-repeat;
      position: relative;
    }
    .database {
      width: 360px;
      height: 260px;
      background: url('@ass/img/1.0.0/icon_zhnydk_nrbg2.svg') no-repeat;
      position: relative;
      margin: 23px 0;
    }
    .safety_monitoring {
      width: 360px;
      height: 239px;
      background: url('@ass/img/1.0.0/icon_zhnydk_nrbg3.svg') no-repeat;
      position: relative;
    }
  }
  .center {
    margin: 0 20px;
    .c_top {
      height: 726px;
      position: relative;
      .groundlist {
        right: 36px;
        bottom: 56px;
        position: absolute;
        .number_group {
          display: flex;
          flex-direction: column;
          align-items: center;
          .font1 {
            font-size: 15px;
            color: #c2daff;
            line-height: 15px;
          }
          .number {
            display: flex;
            align-items: flex-end;
            margin-top: 3px;
            font-family: 'num';
            font-size: 29px;
            font-weight: bold;
            color: #ffea16;
            line-height: 29px;
          }
        }
      }

      img {
        height: 100%;
        width: auto;
      }
    }
    .c_bottom {
      margin-top: 20px;
      height: 198px;
      width: 100%;
      background: url('@ass/img/1.0.0/icon_zhnydk_nrbg411.svg') no-repeat;
      background-size: 100%;
      position: relative;
    }
  }
  .right {
    .data_security {
      width: 360px;
      height: 240px;
      background: url('@ass/img/1.0.0/icon_zhnydk_nrbg5.svg') no-repeat;
      position: relative;
    }
    .host_protection {
      margin: 23px 0;
      width: 360px;
      height: 240px;
      background: url('@ass/img/1.0.0/icon_zhnydk_nrbg5.svg') no-repeat;
      position: relative;
    }
    .system_protection {
      width: 360px;
      height: 420px;
      background: url('@ass/img/1.0.0/icon_zhnydk_nrbg7.svg') no-repeat;
      position: relative;
    }
  }
}
</style>
