<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-15 14:36:32
 * @LastEditTime: 2022-04-26 18:23:53
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \networksecurity\src\views\rightProduct\index.vue
-->
<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-14 10:48:04
 * @LastEditTime: 2022-04-14 11:28:17
 * @LastEditors: cyy
 * @Description: 右侧数据
 * @FilePath: \networksecurity\src\views\rightProduct.vue
-->
<template>
  <div id="rightProduct">
    <!-- 主机业务安全 -->
    <host-service :list="zjywaq" :bar="bar"></host-service>
    <!-- 设备安全 -->
    <equipment :list="sbaq"></equipment>
    <!-- 网络安全 -->
    <network></network>
    <!-- 数据安全 -->
    <data-Security :list="sjaq"></data-Security>
  </div>
</template>

<script>
import equipment from './equipment.vue'
import dataSecurity from './dataSecurity.vue'
import network from './network.vue'
import hostService from './hostService.vue'
export default {
  components: {
    hostService,
    equipment,
    dataSecurity,
    network,
  },

  data() {
    return {
      bar:[],
      zjywaq: [],
      sbaq: [],
      sjaq: [],
    }
  },

  created() {
    this.getrightProduct()
  },

  methods: {
    async getrightProduct() {
      const {
        bar,
        list: { sbaq, sjaq, zjywaq },
      } = await this.$http.get('/index/rightProduct')
      this.zjywaq = zjywaq
      this.sbaq = sbaq
      this.sjaq = sjaq
      this.bar = bar
    },
  },
}
</script>

<style lang="scss" scoped>
#rightProduct {
  display: flex;
  padding: 0 70px;
  justify-content: center;
}
</style>

