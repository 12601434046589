import { render, staticRenderFns } from "./hostService.vue?vue&type=template&id=0029ee22&scoped=true&"
import script from "./hostService.vue?vue&type=script&lang=js&"
export * from "./hostService.vue?vue&type=script&lang=js&"
import style0 from "./index.scss?vue&type=style&index=0&id=0029ee22&lang=scss&scoped=true&"
import style1 from "./hostService.vue?vue&type=style&index=1&id=0029ee22&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0029ee22",
  null
  
)

export default component.exports