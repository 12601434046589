/*
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-12 10:48:48
 * @LastEditTime: 2022-04-25 15:19:13
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \networksecurity\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@view/HomeView.vue'
import leftProduct from '@view/leftProduct/index.vue'
import rightProduct from '@view/rightProduct/index.vue'
import securityoperationCenter from '@view/securityoperationCenter/index.vue'
import powersupplyService from '@view/powersupplyService/index.vue'
import northeastData from '@view/northeastData/index.vue'
import safetyProtection from '@view/safetyProtection/index.vue'
import smartEnergy from '@view/smartEnergy/index.vue'
import marketingData from '@view/marketingData/index.vue'
import login from '@view/login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },

  {
    path: '/login',
    name: 'login',
    component: login,
  },

  {
    path: '/leftProduct',
    name: 'leftProduct',
    component: leftProduct,
  },
  {
    path: '/rightProduct',
    name: 'rightProduct',
    component: rightProduct,
  },
  {
    path: '/securityoperationCenter',
    name: 'securityoperationCenter',
    component: securityoperationCenter,
  },
  {
    path: '/powersupplyService',
    name: 'powersupplyService',
    component: powersupplyService,
  },
  {
    path: '/northeastData',
    name: 'northeastData',
    component: northeastData,
  },
  {
    path: '/safetyProtection',
    name: 'safetyProtection',
    component: safetyProtection,
  },
  {
    path: '/smartEnergy',
    name: 'smartEnergy',
    component: smartEnergy,
  },
  {
    path: '/marketingData',
    name: 'marketingData',
    component: marketingData,
  },
]

const router = new VueRouter({
  routes,
})

export default router

router.beforeEach((to, from, next) => {
  // 1.如果访问的是登录页面（无需权限），直接放行
  if (to.path === '/login' || to.path === '/register') return next()
  // 2.如果访问的是有登录权限的页面，先要获取token
  const tokenStr = window.sessionStorage.getItem('token')
  // 2.1如果token为空，强制跳转到登录页面；否则，直接放行
  if (!tokenStr) {
    return next('/login')
  }
  next()
})

/**
 * 1,已经登陆了，是否能同过改变地址的哈希值再次回到登录页
 * 2，已经登陆了，再次点开时，是未登录的状态，（token存在sessionStorage中）
 */