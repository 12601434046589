<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-14 17:52:32
 * @LastEditTime: 2022-04-26 16:44:48
 * @LastEditors: cyy
 * @Description: 实验仿真平台
 * @FilePath: \networksecurity\src\views\leftProduct\experimentalSimulation.vue
-->
<template>
  <div id="experimentalSimulation">
    <div class="item ml20 mr20">
      <div class="lp_title">实验仿真平台</div>
      <template v-if="list.length > 0">
        <div class="basics">
          <div class="list">
            <div class="l_item">
              <div class="tu">
                <img src="@ass/img/1.0.0/icon_4+4_zds.svg" alt="" />
              </div>
              <div class="right">
                <div class="number">
                  <animate-number
                    from="0"
                    :to="list[0].value"
                    easing="easeOutQuad"
                    style="display: block"
                  ></animate-number>
                </div>
                <div class="name">{{ list[0].tip }}</div>
              </div>
            </div>
            <div class="l_item">
              <div class="tu">
                <img src="@ass/img/1.0.0/icon_4+4_jcwt.svg" alt="" />
              </div>
              <div class="right">
                <div class="number">
                  <animate-number
                    from="0"
                    :to="list[1].value"
                    easing="easeOutQuad"
                    style="display: block"
                  ></animate-number>
                </div>
                <div class="name">{{ list[1].tip }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="basics mt50">
          <div class="list">
            <div class="l_item">
              <div class="tu">
                <img src="@ass/img/1.0.0/icon_4+4_zdlx.svg" alt="" />
              </div>
              <div class="right">
                <div class="number">
                  <animate-number
                    from="0"
                    :to="list[2].value"
                    easing="easeOutQuad"
                    style="display: block"
                  ></animate-number>
                </div>
                <div class="name">{{ list[2].tip }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="frame mt62">
        <div class="f_title">ARM架构终端仿真验证</div>
        <div class="f_tu">
          <img src="@ass/img/1.0.0/img_4+4_armjg.png" alt="" />
        </div>
      </div>
      <div class="frame mt90">
        <div class="f_title">X86架构终端仿真验证</div>
        <div class="f_tu">
          <img src="@ass/img/1.0.0/img_4+4_x86jg.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'experimentalSimulation',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss" src="./index.scss" scoped>
</style>

<style lang="scss" scoped>
#experimentalSimulation {
  .mt50 {
    margin-top: 50px;
  }
  .mt90 {
    margin-top: 90px;
  }
  .mt62 {
    margin-top: 62px;
  }
  .frame {
    .f_tu {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>