<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-14 10:48:04
 * @LastEditTime: 2022-06-10 10:15:37
 * @LastEditors: cyy
 * @Description: 供电服务指挥
 * @FilePath: \networksecurity\src\views\powersupplyService\index.vue
-->

<template>
  <div id="powersupplyService">
    <div class="left">
      <div class="bottom_list" v-if="gl.length > 0">
        <div class="bottom_item bg1">
          <div class="b_title">概览</div>
          <protection-index
            :protection_index="protection_index"
          ></protection-index>
          <div class="datalist mt30">
            <div class="d_item">
              <div class="tu">
                <img src="@ass/img/1.0.0/icon_gdzh_bjaqyj.svg" alt="" />
              </div>
              <div class="name">{{ gl[1].tip }}</div>
              <div class="number">
                <animate-number
                  from="0"
                  :to="gl[1].value"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </div>
            </div>
            <div class="d_item">
              <div class="tu">
                <img src="@ass/img/1.0.0/icon_gdzh_ldjc.svg" alt="" />
              </div>
              <div class="name">{{ gl[2].tip }}</div>
              <div class="number">
                <animate-number
                  from="0"
                  :to="gl[2].value"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </div>
            </div>
          </div>
          <div class="datalist">
            <div class="d_item">
              <div class="tu">
                <img src="@ass/img/1.0.0/icon_gdzh_bdff.svg" alt="" />
              </div>
              <div class="name">{{ gl[3].tip }}</div>
              <div class="number">
                <animate-number
                  from="0"
                  :to="gl[3].value"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </div>
            </div>
            <div class="d_item">
              <div class="tu">
                <img src="@ass/img/1.0.0/icon_gdzh_aqgl.svg" alt="" />
              </div>
              <div class="name">{{ gl[4].tip }}</div>
              <div class="number">
                <animate-number
                  from="0"
                  :to="gl[4].value"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </div>
            </div>
          </div>
          <div class="datalist">
            <div class="d_item">
              <div class="tu">
                <img src="@ass/img/1.0.0/icon_gdzh_zdaqzr.svg" alt="" />
              </div>
              <div class="name">{{ gl[5].tip }}</div>
              <div class="number">
                <animate-number
                  from="0"
                  :to="gl[5].value"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </div>
            </div>
            <div class="d_item">
              <div class="tu">
                <img src="@ass/img/1.0.0/icon_gdzh_ywsj.svg" alt="" />
              </div>
              <div class="name">{{ gl[6].tip }}</div>
              <div class="number">
                <animate-number
                  from="0"
                  :to="gl[6].value"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom_list mt36" v-if="pwxsgk.length > 0">
        <div class="bottom_item bg2">
          <div class="b_title">配网巡视概况</div>
          <div class="b_name">{{ pwxsgk[0].tip }}</div>
          <div class="number _center" style="font-size: 50px">
            <animate-number
              from="0"
              :to="pwxsgk[0].value"
              easing="easeOutQuad"
              style="display: block"
            ></animate-number>
          </div>
          <div class="datalist mt30">
            <div class="d_item">
              <div class="tu">
                <img src="@ass/img/1.0.0/icon_gdzh_yxbx.svg" alt="" />
              </div>
              <div class="name">{{ pwxsgk[1].tip }} / {{ pwxsgk[2].tip }}</div>
              <div class="number">
                <animate-number
                  from="0"
                  :to="pwxsgk[1].value"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
                <span>/</span>
                <animate-number
                  from="0"
                  :to="pwxsgk[2].value"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </div>
            </div>
            <div class="d_item">
              <div class="tu">
                <img src="@ass/img/1.0.0/icon_gdzh_qxyh.svg" alt="" />
              </div>
              <div class="name">{{ pwxsgk[3].tip }} / {{ pwxsgk[4].tip }}</div>
              <div class="number">
                <animate-number
                  from="0"
                  :to="pwxsgk[3].value"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
                <span>/</span>
                <animate-number
                  from="0"
                  :to="pwxsgk[4].value"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="center cp" @click="open('http://10.160.180.15:28088/Portal')">
      <div class="header">
        <div class="f_title mb20 m-auto" style="margin-left: 201px">
          设备标签化管理业务展示
        </div>
        <div class="f_title mb20 h2 m-auto">智能移动巡检作业业务展示</div>
      </div>
      <div class="c_v_top">
        <div class="img_bottm">
          <img src="@ass/img/1.0.0/icon_gdzh_cbg.svg" alt="" />
        </div>
        <div class="c_contain mt10">
          <div class="c_contain1">
            <img src="@ass/img/1.0.0/icon_gdzh_gzc.svg" alt="" />
          </div>
          <div class="c_contain2">
            <div class="contain2_bg1">
              <div class="contain2_name">PMS</div>
            </div>
            <div class="contain2_bg2">
              <div class="contain2_name">DMS</div>
            </div>
            <div class="contain2_bg3">
              <div class="contain2_name">用电信息采集系统</div>
            </div>
          </div>
          <div class="c_contain3">
            <div class="contain3_bg1">
              <div class="contain2_name">PMS</div>
            </div>
            <div class="contain3_bg2"><div class="contain2_name">GIS</div></div>
          </div>
        </div>
        <div class="img_bottm2">
          <img src="@ass/img/1.0.0/icon_gdzh_cbg.svg" alt="" />
        </div>
        <div class="c_contain" style="margin-top: 38px">
          <div class="c_contain1 mt50">
            <img src="@ass/img/1.0.0/icon_gdzh_wlc.svg" alt="" />
          </div>
          <div class="c_list">
            <div class="c_item">
              <div class="c_tu">
                <img src="@ass/img/1.0.0/icon_gdzh_fhq.svg" alt="" />
              </div>
              <div class="name">防火墙</div>
            </div>
            <div class="c_item">
              <div class="c_tu">
                <img src="@ass/img/1.0.0/icon_gdzh_xxgl.svg" alt="" />
              </div>
              <div class="name">信息隔离</div>
            </div>
            <div class="c_item">
              <div class="c_tu">
                <img src="@ass/img/1.0.0/icon_gdzh_ids.svg" alt="" />
              </div>
              <div class="name">IDS</div>
            </div>
            <div class="c_item">
              <div class="c_tu">
                <img src="@ass/img/1.0.0/icon_gdzh_ips.svg" alt="" />
              </div>
              <div class="name">IPS</div>
            </div>
            <div class="c_item">
              <div class="c_tu">
                <img src="@ass/img/1.0.0/icon_gdzh_qgl.svg" alt="" />
              </div>
              <div class="name">强隔离</div>
            </div>
            <div class="c_item">
              <div class="c_tu">
                <img src="@ass/img/1.0.0/icon_gdzh_wg.svg" alt="" />
              </div>
              <div class="name">网关</div>
            </div>
          </div>
        </div>
      </div>
      <div class="c_v_center">
        <div class="img_bottm3">
          <img src="@ass/img/1.0.0/icon_gdzh_cbg.svg" alt="" />
        </div>
        <div class="light">
          <img src="@ass/img/1.0.0/icon_gdzh_yycbg2.svg" alt="" />
        </div>
        <div class="light2">
          <img src="@ass/img/1.0.0/icon_gdzh_yycbg2.svg" alt="" />
        </div>
        <div class="c_center_contain">
          <div class="c_tu">
            <img src="@ass/img/1.0.0/icon_gdzh_yyc.svg" alt="" />
          </div>
          <div class="c_center_list flex-column-center">
            <div class="center_title">设备标签化管理</div>
            <div class="d_flex">
              <div class="c_list_item mr40">
                <div class="l_item">建立标签</div>
                <div class="l_item">巡视时间</div>
                <div class="l_item">巡视要求</div>
                <div class="l_item">标签规划</div>
                <div class="l_item">标签大类</div>
              </div>
              <div class="c_list_item">
                <div class="l_item">标签匹配线路</div>
                <div class="l_item">变电站</div>
                <div class="l_item">线路</div>
                <div class="l_item">设备选择</div>
              </div>
            </div>
          </div>
          <div class="c_center_list flex-column-center ml55mr123">
            <div class="center_title">设备标签化查询统计</div>
            <div class="c_list_item">
              <div class="l_item">设备类型</div>
              <div class="l_item">设备名</div>
              <div class="l_item">变电站</div>
              <div class="l_item">单位</div>
            </div>
          </div>
          <div class="c_center_list flex-column-center">
            <div class="center_title">配网巡视管理</div>
            <div class="c_list_item">
              <div class="l_item">缺陷上报</div>
              <div class="l_item">设备修订</div>
              <div class="l_item">必巡设置</div>
              <div class="l_item">档案信息</div>
              <div class="l_item">数据登记</div>
              <div class="l_item">导航</div>
              <div class="l_item">红外线拍照</div>
            </div>
          </div>
          <div class="c_center_list flex-column-center mlmr60">
            <div class="center_title">配网缺陷管理</div>
            <div class="c_list_item">
              <div class="l_item">定位</div>
              <div class="l_item">上报</div>
              <div class="l_item">缺陷分级</div>
              <div class="l_item">缺陷完善</div>
            </div>
          </div>
          <div class="c_center_list flex-column-center">
            <div class="center_title">配网隐患管理</div>
            <div class="c_list_item">
              <div class="l_item">边界安全</div>
              <div class="l_item">漏洞监测</div>
              <div class="l_item">病毒防范</div>
              <div class="l_item">安全隔离</div>
              <div class="l_item">终端安全准入</div>
              <div class="l_item">业务行为审计</div>
            </div>
          </div>
        </div>
      </div>
      <div class="c_v_bottom">
        <div class="img_c_v_bottom">
          <img src="@ass/img/1.0.0/icon_gdzh_cbg.svg" alt="" />
        </div>
        <div class="d-flex">
          <div class="c_v_bottom_tu">
            <img src="@ass/img/1.0.0/icon_gdzh_zsc.svg" alt="" />
          </div>
          <div class="c_v_bottom_list">
            <div class="bottom_list_item">
              <div class="tu">
                <img src="@ass/img/1.0.0/icon_gdzh_gdfwzhxt.svg" alt="" />
              </div>
              <div class="name">供电服务指挥系统</div>
            </div>
            <div class="bottom_list_item" style="margin-left: 153px">
              <div class="tu">
                <img src="@ass/img/1.0.0/icon_gdzh_zsgfapp.svg" alt="" />
              </div>
              <div class="name">掌上供服App</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="bottom_list mb40">
        <div class="bottom_item bg3">
          <div class="b_title">病毒扫描</div>
          <pie :acquiredOption="configure" height="260px"></pie>
        </div>
      </div>
      <div class="bottom_list mb40">
        <div class="bottom_item bg3">
          <div class="b_title">告警分布</div>
          <div class="pt20">
            <distribution :gjfb="gjfb"></distribution>
          </div>
        </div>
      </div>
      <div class="bottom_list">
        <div class="bottom_item bg3">
          <div class="b_title">配网缺陷占比</div>
          <pie :acquiredOption="defect" height="260px"></pie>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import distribution from './components/distribution.vue'
import pie from '@cm/charts/pie'
import protectionIndex from './components/protectionIndex.vue'
export default {
  components: {
    protectionIndex,
    pie,
    distribution,
  },

  data() {
    return {
      defect: {},
      configure: {},
      bdsm: [],
      gjfb: [],
      gl: [],
      pwqxzb: [],
      pwxsgk: [],
      protection_index: {},
    }
  },

  watch: {
    pwqxzb: {
      handler(val) {
        if (val.length > 0) {
          this.getdefect()
        }
      },
      immediate: true,
    },

    bdsm: {
      handler(val) {
        if (val.length > 0) {
          this.getconfigure()
        }
      },
      immediate: true,
    },
  },

  created() {
    this.getpowersupplyService()
  },

  methods: {
    open(url) {
      window.open(url)
    },
    async getpowersupplyService() {
      const {
        list: { bdsm, gjfb, gl, pwqxzb, pwxsgk },
      } = await this.$http.get('/index/powersupplyService')
      this.bdsm = bdsm
      this.gjfb = gjfb
      this.protection_index = gl.slice(0, 1)[0]
      this.gl = gl
      this.pwqxzb = pwqxzb
      this.pwxsgk = pwxsgk
    },

    getdefect() {
      let data1 = this.pwqxzb.map((item) => {
        let color =
          item.name == 'confirmed'
            ? '#2FCF5A'
            : item.name == 'to_be_confirmed'
            ? '#5781ED'
            : '#8E8C79'
        return {
          value: item.value,
          name: item.tip,
          itemStyle: {
            color,
          },
        }
      })
      this.defect = {
        legend: {
          bottom: '0%',
          itemWidth: 12,
          itemHeight: 12,
          textStyle: {
            color: '#fff',
          },
          itemGap: 30,
        },

        series: [
          {
            width: '128',
            type: 'pie',
            left: '66px',
            bottom: '20px',
            radius: ['60%', '80%'],
            avoidLabelOverlap: false,

            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold',
              },
            },

            label: {
              show: false,
            },

            data: data1,
          },
        ],
      }
    },
    getconfigure() {
      let data1 = this.bdsm.map((item) => {
        let color =
          item.name == 'illegal_business_attack'
            ? '#44C8EE'
            : item.name == 'malformed_message'
            ? '#ED576E'
            : item.name == 'conventional_attack'
            ? '#DECA04'
            : '#5774FA'
        return {
          value: item.value,
          name: item.tip,
          itemStyle: {
            color,
          },
        }
      })
      this.configure = {
        legend: {
          bottom: '0%',
          itemWidth: 12,
          itemHeight: 12,
          textStyle: {
            color: '#fff',
          },
          itemGap: 16,
        },

        series: [
          {
            width: '128',
            type: 'pie',
            left: '66px',
            bottom: '20px',
            radius: ['60%', '80%'],
            avoidLabelOverlap: false,

            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold',
              },
            },

            label: {
              show: false,
            },

            data: data1,
          },
        ],
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#powersupplyService {
  display: flex;
  padding: 0 20px;
  .m-auto {
    margin: 0 auto;
  }
  img {
    height: 100%;
    width: auto;
  }
  .ml55mr123 {
    margin: 0 123px 0 55px;
  }
  .mlmr60 {
    margin: 0 60px;
  }
  .mb40 {
    margin-bottom: 40px;
  }
  .mt36 {
    margin-top: 36px;
  }
  .left {
    width: 260px;
  }
  .center {
    width: 1319px;
    margin: 0 20px;
    .header {
      display: flex;
      justify-content: center;
      .h2 {
        transform: translateX(-60px);
      }
    }
    .c_v_top {
      height: 292px;
      width: 100%;
      background: url('@ass/img/1.0.0/icon_gdzh_jt01.svg') no-repeat;
      background-size: 663px 228px;
      background-position: 299px 0;
      position: relative;
      .c_list {
        display: flex;
        margin: 75px 0 0 195px;
        .c_item {
          margin-right: 108px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .c_tu {
            width: 40px;
            margin-bottom: 10px;
            display: flex;
            justify-content: center;
          }
          .name {
            font-size: 14px;
            font-weight: bold;
            color: #ffffff;
            line-height: 14px;
            text-align: center;
          }
        }
      }
      .img_bottm,
      .img_bottm2 {
        position: absolute;
        top: 50px;
        left: 79px;
        width: 1240px;
        height: 68px;
      }
      .img_bottm2 {
        top: 215px;
      }
      .c_contain {
        padding-top: 38px;
        display: flex;
        align-items: center;
        height: 90px;
        box-sizing: border-box;

        .c_contain1 {
          width: 113px;
          height: 89px;
          img {
            width: 100%;
          }
        }
        .contain2_name {
          margin-top: 25px;
          font-size: 14px;
          font-weight: bold;
          color: #ffffff;
          line-height: 18px;
          text-align: center;
        }
        .c_contain2 {
          display: flex;
          align-items: center;
          margin-left: 118px;
          margin-top: 46px;

          .contain2_bg1 {
            width: 65px;
            height: 44px;
            background: url('@ass/img/1.0.0/icon_gdzh_pms.svg') no-repeat;
            background-size: 65px 44px;
          }
          .contain2_bg2 {
            margin: 0 51px;
            width: 65px;
            height: 44px;
            background: url('@ass/img/1.0.0/icon_gdzh_dms.svg') no-repeat;
            background-size: 65px 44px;
          }
          .contain2_bg3 {
            display: flex;
            justify-content: center;
            height: 44px;
            background: url('@ass/img/1.0.0/icon_gdzh_ydxxcj.svg') no-repeat;
            background-size: 65px 44px;
            background-position: center;
          }
        }
        .c_contain3 {
          display: flex;
          align-items: center;
          margin-top: 46px;
          margin-left: 290px;
          .contain3_bg1 {
            width: 65px;
            height: 44px;
            background: url('@ass/img/1.0.0/icon_gdzh_pms.svg') no-repeat;
            background-size: 65px 44px;
          }
          .contain3_bg2 {
            margin: 0 51px;
            width: 65px;
            height: 44px;
            background: url('@ass/img/1.0.0/icon_gdzh_gis.svg') no-repeat;
            background-size: 65px 44px;
          }
        }
      }
    }

    .c_v_center {
      height: 414px;
      width: 100%;
      background: url('@ass/img/1.0.0/icon_gdzh_jt02.svg') no-repeat;
      background-size: 939px 355px;
      background-position: 204px 0;
      position: relative;
      .img_bottm3 {
        position: absolute;
        bottom: 0;
        left: 79px;
        width: 1240px;
        height: 68px;
      }
      .light,
      .light2 {
        position: absolute;
        top: 212px;
        left: 130px;
        width: 499px;
        height: 202px;
      }
      .light2 {
        left: 723px;
      }
      .c_center_contain {
        display: flex;
        padding-top: 36px;
        .c_tu {
          margin-top: 132px;
          width: 113px;
          margin-right: 40px;
          img {
            width: 100%;
            height: auto;
          }
        }
        .c_center_list {
          .center_title {
            margin-bottom: 13px;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            color: #ffffff;
            line-height: 23px;
          }
          .c_list_item {
            .l_item {
              font-size: 16px;
              color: #ffffff;
              line-height: 16px;
              width: 106px;
              height: 27px;
              background: rgba(34, 108, 152, 0.28);
              box-shadow: inset 0px 0px 8px 0px #0082f3;
              opacity: 0.86;
              border: 1px solid #0b808c;
              margin-bottom: 18px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
    .c_v_bottom {
      padding-top: 46px;
      height: 163px;
      width: 100%;
      background: url('@ass/img/1.0.0/icon_gdzh_jt03.svg') no-repeat;
      background-size: 586px 55px;
      background-position: 398px 0;
      position: relative;
      box-sizing: border-box;
      .img_c_v_bottom {
        position: absolute;
        bottom: 0;
        left: 79px;
        width: 1240px;
        height: 68px;
      }
      .c_v_bottom_list {
        margin-left: 402px;
        display: flex;
        .bottom_list_item {
          display: flex;
          flex-direction: column;
          align-items: center;
          .tu {
            height: 85px;
          }
          .name {
            font-size: 14px;
            font-weight: bold;
            color: #ffffff;
          }
        }
      }
    }
  }
  .number {
    font-family: 'num';
    font-size: 14px;
    color: #fff;
    display: flex;
    justify-content: center;
  }

  .bottom_list {
    .datalist {
      margin-bottom: 28px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .d_item {
        display: flex;
        flex-direction: column;
        align-items: center;

        .tu {
          height: 28px;
        }
        .name {
          margin: 10px 0 14px;
          font-size: 14px;
          color: #ffffff;
          line-height: 14px;
        }
      }
    }
    .dv-scroll-board {
      width: 90%;
      margin: 30px 10px;
    }
    .b_title {
      position: absolute;
      top: -11px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
    }
    .b_name {
      padding-top: 60px;
      font-size: 14px;
      text-align: center;
      margin-bottom: 10px;
      color: #ffffff;
      line-height: 14px;
    }
    .bottom_item {
      position: relative;
    }
    .bg3 {
      width: 260px;
      height: 283px;
      background: url('@ass/img/1.0.0/icon_gdzh_nrbg5.png') no-repeat;
    }

    .bg2 {
      width: 260px;
      height: 313px;
      background: url('@ass/img/1.0.0/icon_gdzh_nrbg2.svg') no-repeat;
    }

    .bg1 {
      width: 260px;
      height: 583px;
      background: url('@ass/img/1.0.0/icon_gdzh_nrbg1.svg') no-repeat;
    }
  }
  .b_title {
    position: absolute;
    top: -11px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
  }
}
</style>
