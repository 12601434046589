<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-12 10:48:48
 * @LastEditTime: 2022-04-26 16:45:57
 * @LastEditors: cyy
 * @Description:左侧数据
 * @FilePath: \networksecurity\src\views\leftProduct\index.vue
-->
<template>
  <div class="leftProduct">
    <!-- 统一密码基础设施 -->
    <unified-password :chart="chart" :list="list.tymmjcss"></unified-password>
    <!-- 实验仿真平台 -->
    <experimental-simulation :list="list.syfzpt"></experimental-simulation>
    <!-- 攻防靶场 -->
    <attackanddefense-range :list="list.gfbc"></attackanddefense-range>
    <!-- 安全芯片 -->
    <security-chip :list="list.aqxp"></security-chip>
  </div>
</template>

<script>
import securityChip from './securityChip.vue'
import attackanddefenseRange from './attackanddefenseRange.vue'
import experimentalSimulation from './experimentalSimulation.vue'
import unifiedPassword from './unifiedPassword.vue'
export default {
  name: 'leftProduct',

  components: {
    unifiedPassword,
    experimentalSimulation,
    attackanddefenseRange,
    securityChip,
  },

  data() {
    return {
      list: {},
      chart: [],
    }
  },

  created() {
    this.getleftProduct()
  },

  methods: {
    async getleftProduct() {
      const { list, chart } = await this.$http.get('/index/leftProduct')
      this.list = list
      this.chart = chart
    },
  },
}
</script>

<style lang="scss" scoped>
.leftProduct {
  display: flex;
  padding: 0 70px;
  justify-content: center;
}
</style>
