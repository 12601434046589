<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-15 14:46:00
 * @LastEditTime: 2022-06-10 11:38:26
 * @LastEditors: cyy
 * @Description: 主机业务安全
 * @FilePath: \networksecurity\src\views\rightProduct\hostService.vue
-->
<template>
  <div class="hostService">
    <div class="item ml20 mr20">
      <div class="lp_title">主机业务安全</div>
      <div
        class="contianclick cp"
        @click="open('http://10.160.180.15:28088/Portal')"
      >
        <div class="f_title mb25">主机业务安全系统</div>
        <div class="basics">
          <div class="list" v-if="list.length > 0">
            <div class="l_item">
              <div class="tu">
                <img src="@ass/img/1.0.0/icon_4+4_yypt.svg" alt="" />
              </div>
              <div class="right">
                <div class="number">
                  <animate-number
                    from="0"
                    :to="list[0].value"
                    easing="easeOutQuad"
                    style="display: block"
                  ></animate-number>
                </div>
                <div class="name">{{ list[0].tip }}</div>
              </div>
            </div>
            <div class="l_item">
              <div class="tu">
                <img src="@ass/img/1.0.0/icon_4+4_yyss.svg" alt="" />
              </div>
              <div class="right">
                <div class="number">
                  <animate-number
                    from="0"
                    :to="list[2].value"
                    easing="easeOutQuad"
                    style="display: block"
                  ></animate-number>
                </div>
                <div class="name">{{ list[2].tip }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="chart">
          <div class="leftchart">
            <div ref="Proportion" style="width: 100%; height: 200px"></div>
          </div>
          <div class="rightchart">
            <div ref="hostStatus" style="width: 100%; height: 200px"></div>
          </div>
        </div>
      </div>
      <!-- @click="
          open(
            'http://123.57.62.100:8080/icdcs/index.html'
          )
        " -->
      <div class="contianclick">
        <div class="f_title mt60 mb20">主机网络诱骗系统</div>
        <div class="basics">
          <div class="list" v-if="list.length > 0">
            <div class="l_item">
              <div class="tu">
                <img src="@ass/img/1.0.0/icon_4+4-2_xnbd.svg" alt="" />
              </div>
              <div class="right">
                <div class="number">
                  <animate-number
                    from="0"
                    :to="list[1].value"
                    easing="easeOutQuad"
                    style="display: block"
                  ></animate-number>
                </div>
                <div class="name">{{ list[1].tip }}</div>
              </div>
            </div>
            <div class="l_item">
              <div class="tu">
                <img src="@ass/img/1.0.0/icon_4+4-2_zjrz.svg" alt="" />
              </div>
              <div class="right">
                <div class="number">
                  <animate-number
                    from="0"
                    :to="list[7].value"
                    easing="easeOutQuad"
                    style="display: block"
                  ></animate-number>
                </div>
                <div class="name">{{ list[7].tip }}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="distribution mt10"
          style="width: 95%; height: 200px"
          ref="distribution"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'hostService',
  mounted() {
    this.getdistribution()
    this.Proportion()
    this.getHoststatus()
  },

  props: {
    bar: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    list: {
      handler(val) {
        if (val.length > 0) {
          this.Proportion()
          this.getHoststatus()
          this.getdistribution()
        }
      },
      immediate: true,
    },
  },

  methods: {
    open(url) {
      window.open(url)
    },
    getdistribution() {
      let myChart = this.$echarts.getInstanceByDom(this.$refs.distribution)
      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.distribution)
      }

      let vallist = this.list.slice(8, 10)
      vallist.forEach((item, index) => {
        index == 0 ? (item.tip = 'CVE') : (item.tip = 'MS')
      })
      let xAxisData = vallist.map((item) => item.tip)
      let yAxisData = vallist.map((item) => item.value)

      let option = {
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          left: '20%',
        },
        title: {
          text: '补丁分布',
          left: 'center',
          top: '10%',
          textStyle: {
            fontSize: '17px',
            color: '#fff',
          },
        },

        xAxis: {
          type: 'category',
          data: xAxisData,
          axisLabel: {
            show: true,
            color: '#fff',
          },
        },

        yAxis: {
          type: 'value',
          data: yAxisData,
          splitLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              // type: 'dashed',
              color: '#293A5F',
            },
          },
        },
        series: [
          {
            barMaxWidth: '34px',
            data: [
              {
                value: 200,
                itemStyle: {
                  color: '#24C776',
                },
              },
              {
                value: 100,
                itemStyle: {
                  color: '#316ADC',
                },
              },
            ],
            type: 'bar',
          },
        ],
      }
      myChart.setOption(option)
    },

    getHoststatus() {
      let myChart = this.$echarts.getInstanceByDom(this.$refs.hostStatus)
      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.hostStatus)
      }

      let option = {
        title: {
          text: '主机状态',
          left: 'center',
          top: '0%',
          textStyle: {
            fontSize: '17px',
            color: '#fff',
          },
        },

        legend: {
          bottom: '0%',
          left: 'center',

          itemWidth: 15,
          textStyle: {
            color: '#fff',
          },
        },

        tooltip: {},

        grid: {
          width: '80%',
          left: '20%',
          height: '40%',
          bottom: '30%',
        },

        dataset: {
          source: this.bar,
        },
        xAxis: [
          {
            type: 'category',
            axisLabel: {
              show: true,
              color: '#fff',
            },
          },
        ],
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              // type: 'dashed',
              color: '#293A5F',
            },
          },
        },
        series: [
          {
            type: 'bar',
            barWidth: '15%',
            itemStyle: {
              color: '#24C776',
            },
          },
          {
            type: 'bar',
            barWidth: '15%',
            itemStyle: {
              color: '#316ADC',
            },
          },
        ],
      }

      myChart.setOption(option)
    },
    Proportion() {
      let myChart = this.$echarts.getInstanceByDom(this.$refs.Proportion)
      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.Proportion)
      }
      let vallist = this.list.slice(10, 12)

      let val1 = vallist.map((item) => {
        let name = item.name == 'win' ? 'Windows' : 'Linux'
        let color = item.name == 'win' ? '#44C8EE' : '#ED576E'
        return {
          value: item.value,
          name,
          itemStyle: {
            color,
          },
        }
      })
      let val2 = val1.map((item) => item.name)
      let option = {
        title: {
          text: '主机占比',
          left: 'center',
          top: '0%',
          textStyle: {
            fontSize: '17px',
            color: '#fff',
          },
        },

        tooltip: {
          trigger: 'item',
        },

        legend: {
          itemWidth: 15,
          bottom: '0%',
          left: 'center',
          textStyle: {
            color: '#fff',
          },
          data: val2,
        },

        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['40%', '60%'],
            label: {
              show: false,
              alignTo: 'edge',
              formatter: ' {time|{c}}',
              minMargin: 5,
              edgeDistance: 10,
              lineHeight: 15,
              rich: {
                time: {
                  fontSize: 10,
                  color: '#fff',
                },
              },
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold',
              },
            },

            labelLine: {
              length: 7,
              length2: 11,
              show: false,
            },

            data: val1,
          },
        ],
      }
      myChart.setOption(option)
    },
  },
}
</script>

<style lang="scss" src="./index.scss" scoped></style>

<style lang="scss" scoped>
.hostService {
  ::v-deep .f_title {
    margin-left: auto !important;
  }
  .chart {
    width: 100%;
    margin-top: 36px;
    display: flex;
    .leftchart {
      flex: auto;
    }
    .rightchart {
      width: 200px;
    }
  }
}
</style>
