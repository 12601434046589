<template>
  <div>{{ animatedNumber }}</div>
</template>

<script>
import gsap from 'gsap'

export default {
  name: 'animationNumber',

  props: {
    msg: String,
    from: [String, Number],
    to: {
      type: [String, Number],
      default: 0,
    },
    duration: {
      type: [String, Number],
      default: 2000,
    },
    delay: {
      type: [String, Number],
      default: 0,
    },
    easing: String,
  },

  data() {
    return {
      tweenedNumber: 0,
    }
  },

  computed: {
    animatedNumber() {
      return this.tweenedNumber.toFixed(0)
    },
  },

  watch: {
    to: {
      handler() {
        this.start()
      },
    },
  },

  mounted() {
    this.start()
  },

  destroyed() {
    if (this.animation && this.animation.kill) {
      this.animation.kill()
    }
  },

  methods: {
    start() {
      this.animation = gsap.to(this.$data, {
        delay: this.delay / 1000,
        duration: 2,
        tweenedNumber: this.to,
      })
    },
  },
}
</script>

<style scoped lang="scss">
</style>
