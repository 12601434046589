<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-20 14:32:48
 * @LastEditTime: 2022-04-27 11:39:09
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \networksecurity\src\views\powersupplyService\components\protectionIndex.vue
-->
<template>
  <div
    class="protectionIndex"
    ref="protectionIndex"
    style="height: 200px; width: 260px"
  ></div>
</template>

<script>
export default {
  name: 'protectionIndex',
  mounted() {
    this.getprotectionIndex()
  },
  props: {
    protection_index: {
      type: Object,
      default: {
        value: 0,
      },
    },
  },
  watch: {
    protection_index: {
      handler(val) {
        if (Object.keys(val).length > 0) {
          this.getprotectionIndex()
        }
      },
    },
  },
  methods: {
    getprotectionIndex() {
      let myChart = this.$echarts.getInstanceByDom(this.$refs.protectionIndex)
      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.protectionIndex)
      }
      let option = {
        series: [
          {
            name: 'Indicator',
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 100,
            splitNumber: 10,
            detail: {
              fontSize: 30,
              formatter: '{value}%',
              color: '#fff',
              offsetCenter: [0, '-10%'],
            },
            pointer: {
              show: false,
            },
            itemStyle: {
              color: '#4FF0FF',
            },
            progress: {
              show: true,
              width: 20,
            },

            center: ['50%', '70%'],

            data: [
              {
                value: this.protection_index.value,
                name: '防护指数',
              },
            ],
            title: {
              offsetCenter: [0, '30%'],
              color: '#fff',
            },
            axisLine: {
              roundCap: false,
              lineStyle: {
                width: 20,
              },
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
          },
        ],
      }
      myChart.setOption(option)
    },
  },
}
</script>

<style>
</style>