<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-22 18:33:39
 * @LastEditTime: 2022-04-28 16:35:04
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \networksecurity\src\views\smartEnergy\components\dataUsage.vue
-->
<template>
  <div id="dataUsage" ref="dataUsage" style="width: 180px; height: 220px"></div>
</template>

<script>
export default {
  mounted() {
    this.getdata()
  },

  props: {
    dataUsagelist: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    dataUsagelist: {
      handler(val) {
        if (val.length > 0) {
          this.getdata()
        }
      },
      immediate: true,
    },
  },

  methods: {
    getdata() {
      let myChart = this.$echarts.getInstanceByDom(this.$refs.dataUsage)
      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.dataUsage)
      }

      let data1 = this.dataUsagelist.map((item) => {
        let text =
          item.name == 'usage_storage'
            ? '存储'
            : item.name == 'usage_transmission'
            ? '传输'
            : '分发'
        return text
      })

      let data2 = this.dataUsagelist.map((item) => item.value)

      let option = {
        title: {
          left: 'center',
          text: '数据使用',
          show: true,
          textStyle: {
            color: '#fff',
          },
        },
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          left: '15%',
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            show: true,
            color: '#fff',
          },
          data: data1,
        },
        yAxis: {
          type: 'value',
        },

        series: [
          {
            barMaxWidth: '10px',
            itemStyle: {
              color: '#24C776',
            },
            data: data2,
            type: 'bar',
          },
        ],
      }
      myChart.setOption(option)
    },
  },
}
</script>

<style>
</style>