<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-15 14:50:16
 * @LastEditTime: 2022-06-10 10:16:10
 * @LastEditors: cyy
 * @Description: 网络安全
 * @FilePath: \networksecurity\src\views\rightProduct\network.vue
-->
<template>
  <div class="network">
    <div class="item ml20 mr20">
      <div class="lp_title">网络安全</div>
      <div
        class="contianclick cp"
        @click="open('http://10.160.180.15:28088/Portal')"
      >
        <div class="f_title mb20">信息安全网络隔离装置</div>
        <div class="n_tu1">
          <img src="@ass/img/1.0.0/icon_4+4-2_glzz.svg" alt="" />
        </div>
      </div>

      <div class="f_title mb20">安全接入网关</div>
      <div class="n_tu2">
        <img src="@ass/img/1.0.0/icon_4+4-2_jrwg.svg" alt="" />
      </div>
      <div class="bottom_btn">集中管控</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    open(url) {
      window.open(url)
    },
  },
}
</script>

<style lang="scss" src="./index.scss" scoped></style>

<style lang="scss" scoped>
.network {
  img {
    width: 100%;
  }
  .n_tu1 {
    margin: 0 40px 40px;
  }
  .n_tu2 {
    margin: 0 40px 60px;
  }
  .bottom_btn {
    color: #fff;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 357px;
    height: 88px;
    background: rgba(3, 46, 88, 0.24);
    border-radius: 2px;
    border: 2px solid rgba(86, 209, 236, 0.18);
  }
}
</style>
