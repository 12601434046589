<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-14 17:59:51
 * @LastEditTime: 2022-04-26 16:45:24
 * @LastEditors: cyy
 * @Description: 攻防靶场
 * @FilePath: \networksecurity\src\views\leftProduct\attackanddefenseRange.vue
--> 
<template>
  <div id="attackanddefenseRange">
    <div class="item ml20 mr20">
      <div class="lp_title">攻防靶场</div>
      <template v-if="list.length > 0">
        <div class="basics">
          <div class="list">
            <div class="l_item">
              <div class="tu">
                <img src="@ass/img/1.0.0/icon_4+4_kjs.svg" alt="" />
              </div>
              <div class="right">
                <div class="number">
                  <animate-number
                    from="0"
                    :to="list[0].value"
                    easing="easeOutQuad"
                    style="display: block"
                  ></animate-number>
                </div>
                <div class="name">{{ list[0].tip }}</div>
              </div>
            </div>
            <div class="l_item">
              <div class="tu">
                <img src="@ass/img/1.0.0/icon_4+4_xxcj.svg" alt="" />
              </div>
              <div class="right">
                <div class="number">
                  <animate-number
                    from="0"
                    :to="list[1].value"
                    easing="easeOutQuad"
                    style="display: block"
                  ></animate-number>
                </div>
                <div class="name">{{ list[1].tip }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="basics mt50">
          <div class="list">
            <div class="l_item">
              <div class="tu">
                <img src="@ass/img/1.0.0/icon_4+4_tys.svg" alt="" />
              </div>
              <div class="right">
                <div class="number">
                  <animate-number
                    from="0"
                    :to="list[2].value"
                    easing="easeOutQuad"
                    style="display: block"
                  ></animate-number>
                </div>
                <div class="name">{{ list[2].tip }}</div>
              </div>
            </div>

            <div class="l_item">
              <div class="tu">
                <img src="@ass/img/1.0.0/icon_4+4_zjs.svg" alt="" />
              </div>
              <div class="right">
                <div class="number">
                  <animate-number
                    from="0"
                    :to="list[3].value"
                    easing="easeOutQuad"
                    style="display: block"
                  ></animate-number>
                </div>
                <div class="name">{{ list[3].tip }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="frame mt62">
        <div class="f_title">网络安全实训组件-安全实训</div>
        <div class="f_list">
          <div class="f_item">
            <div class="f_tu">
              <img src="@ass/img/1.0.0/icon_4+4_zxpx.svg" alt="" />
            </div>
            <div class="f_name">在线培训</div>
          </div>
          <div class="f_item">
            <div class="f_tu">
              <img src="@ass/img/1.0.0/icon_4+4_fzsy.svg" alt="" />
            </div>
            <div class="f_name">仿真实验</div>
          </div>
          <div class="f_item">
            <div class="f_tu">
              <img src="@ass/img/1.0.0/icon_4+4_jxgl.svg" alt="" />
            </div>
            <div class="f_name">教学管理</div>
          </div>
          <div class="f_item">
            <div class="f_tu">
              <img src="@ass/img/1.0.0/icon_4+4_jnpc.svg" alt="" />
            </div>
            <div class="f_name">技能评测</div>
          </div>
        </div>
      </div>
      <div class="frame mt62">
        <div class="f_title">虚实仿真实训组件-仿真运维</div>
        <div class="f_list">
          <div class="f_item">
            <div class="f_tu">
              <img src="@ass/img/1.0.0/icon_4+4_xxjh.svg" alt="" />
            </div>
            <div class="f_name">虚实结合</div>
          </div>
          <div class="f_item">
            <div class="f_tu">
              <img src="@ass/img/1.0.0/icon_4+4_jspd.svg" alt="" />
            </div>
            <div class="f_name">技术评定</div>
          </div>
          <div class="f_item">
            <div class="f_tu">
              <img src="@ass/img/1.0.0/icon_4+4_zydd.svg" alt="" />
            </div>
            <div class="f_name">资源调度</div>
          </div>
          <div class="f_item">
            <div class="f_tu">
              <img src="@ass/img/1.0.0/icon_4+4_wlcs.svg" alt="" />
            </div>
            <div class="f_name">网络与设备测试</div>
          </div>
        </div>
      </div>
      <div class="frame mt62">
        <div class="f_title">网络攻防竞技组件-比赛竞技</div>
        <div class="f_list">
          <div class="f_item">
            <div class="f_tu">
              <img src="@ass/img/1.0.0/icon_4+4_sszs.svg" alt="" />
            </div>
            <div class="f_name">赛事展示</div>
          </div>
          <div class="f_item">
            <div class="f_tu">
              <img src="@ass/img/1.0.0/icon_4+4_dq.svg" alt="" />
            </div>
            <div class="f_name">CTF夺旗</div>
          </div>
          <div class="f_item">
            <div class="f_tu">
              <img src="@ass/img/1.0.0/icon_4+4_stgl.svg" alt="" />
            </div>
            <div class="f_name">赛题管理</div>
          </div>
          <div class="f_item">
            <div class="f_tu">
              <img src="@ass/img/1.0.0/icon_4+4_gfdk.svg" alt="" />
            </div>
            <div class="f_name">攻防对抗</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'attackanddefenseRange',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss" src="./index.scss" scoped>
</style>

<style lang="scss" scoped>
#attackanddefenseRange {
  .frame {
    margin-top: 30px;
    .f_list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .f_item {
        width: 89px;
        height: 89px;
        background: rgba(34, 108, 152, 0.28);
        box-shadow: inset 0px 0px 9px 0px #0082f3;
        opacity: 0.86;
        border: 2px solid #0b808c;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: 0.2s ease-in;
        .f_name {
          font-size: 17px;
          color: #b3f2f7;
          line-height: 1.5;
          margin-top: 10px;
          text-align: center;
          padding: 0 10px;
        }
        .f_tu {
          height: 23px;
          width: auto;
        }
        &:hover {
          transform: translateY(10px);
        }
      }
    }
  }
}
</style>