<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-14 10:48:04
 * @LastEditTime: 2022-06-10 10:16:17
 * @LastEditors: cyy
 * @Description: 右侧数据
 * @FilePath: \networksecurity\src\views\safetyProtection\index.vue
-->

<template>
  <div id="safetyProtection">
    <div class="left">
      <div class="host2">
        <div class="b_title">主机防护</div>
        <div class="item_list" v-if="zjfh.length > 0">
          <div class="item">
            <div class="name">{{ zjfh[0].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="zjfh[0].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              %
            </div>
          </div>
          <div class="item">
            <div class="name">{{ zjfh[1].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="zjfh[1].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              %
            </div>
          </div>
          <div class="item">
            <div class="name">{{ zjfh[2].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="zjfh[2].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              %
            </div>
          </div>
          <div class="item">
            <div class="name">{{ zjfh[3].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="zjfh[3].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              次
            </div>
          </div>
          <div class="item">
            <div class="name">{{ zjfh[4].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="zjfh[4].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              %
            </div>
          </div>
          <div class="item">
            <div class="name">{{ zjfh[5].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="zjfh[5].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              个
            </div>
          </div>
          <div class="item">
            <div class="name">{{ zjfh[6].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="zjfh[6].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              次
            </div>
          </div>
          <div class="item">
            <div class="name">{{ zjfh[7].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="zjfh[7].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              个
            </div>
          </div>
          <div class="item">
            <div class="name">{{ zjfh[8].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="zjfh[8].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              次
            </div>
          </div>
          <div class="item">
            <div class="name">{{ zjfh[9].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="zjfh[9].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              个
            </div>
          </div>
        </div>
      </div>

      <div class="data">
        <div class="b_title">数据防护</div>
        <div class="item_list" v-if="sjfh.length > 0">
          <div class="item">
            <div class="name">{{ sjfh[0].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="sjfh[0].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              %
            </div>
          </div>
          <div class="item">
            <div class="name">{{ sjfh[1].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="sjfh[1].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              次
            </div>
          </div>
          <div class="item">
            <div class="name">{{ sjfh[2].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="sjfh[2].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              次
            </div>
          </div>
          <div class="item">
            <div class="name">{{ sjfh[3].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="sjfh[3].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              次
            </div>
          </div>
          <div class="item">
            <div class="name">{{ sjfh[4].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="sjfh[4].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              台
            </div>
          </div>
          <div class="item">
            <div class="name">{{ sjfh[5].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="sjfh[5].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              次
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <div
        class="top cp"
        @click="
          open(
            'http://10.160.180.15:28088/Portal'
          )
        "
      >
        <div class="top_list">
          <div class="top_item">采集三级域</div>
          <div class="top_item">生产控制区</div>
          <div class="top_item">安全接入区</div>
          <div class="top_item">通信信道区</div>
          <div class="top_item">终端设备区</div>
        </div>
        <div class="number_contain">
          <div class="item type1">
            <div class="date_title">
              <span class="left_name">系统覆盖</span>
            </div>
            <div class="number" v-if="zdsbq.length > 0">
              <span class="left_number">
                <animate-number
                  from="0"
                  :to="zdsbq[0].value"
                  easing="easeOutQuad"
                  style="display: block"
                ></animate-number>
              </span>
              <span class="right_number">万余户</span>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="bottom_item">
          <div class="b_title">攻击事件</div>
          <dv-scroll-board :config="config1" />
        </div>
        <div class="bottom_item ml20">
          <div class="b_title">溯源事件</div>
          <dv-scroll-board :config="config2" />
        </div>
      </div>
    </div>
    <div class="left">
      <div class="host">
        <div class="b_title">终端防护</div>
        <div class="item_list" v-if="zdfh.length > 0">
          <div class="item">
            <div class="name">{{ zdfh[0].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="zdfh[0].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              %
            </div>
          </div>
          <div class="item">
            <div class="name">{{ zdfh[1].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="zdfh[1].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              %
            </div>
          </div>
          <div class="item">
            <div class="name">{{ zdfh[2].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="zdfh[2].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              %
            </div>
          </div>
          <div class="item">
            <div class="name">{{ zdfh[3].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="zdfh[3].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              个
            </div>
          </div>
        </div>
      </div>

      <div class="host" style="margin: 22px 0">
        <div class="b_title">系统防护</div>
        <div class="item_list" v-if="xtfh.length > 0">
          <div class="item">
            <div class="name">{{ xtfh[0].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="xtfh[0].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              %
            </div>
          </div>
          <div class="item">
            <div class="name">{{ xtfh[1].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="xtfh[1].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              %
            </div>
          </div>
          <div class="item">
            <div class="name">{{ xtfh[2].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="xtfh[2].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              个
            </div>
          </div>
          <div class="item">
            <div class="name">{{ xtfh[3].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="xtfh[3].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              次
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="host mt20 mb20">
        <div class="b_title">主机防护</div>
        <div class="item_list" v-if="zjfh.length > 0">
          <div class="item">
            <div class="name">{{ zjfh[0].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="zjfh[0].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              %
            </div>
          </div>
          <div class="item">
            <div class="name">{{ zjfh[1].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="zjfh[1].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              %
            </div>
          </div>
          <div class="item">
            <div class="name">{{ zjfh[4].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="zjfh[4].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              %
            </div>
          </div>
          <div class="item">
            <div class="name">{{ zjfh[5].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="zjfh[5].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              个
            </div>
          </div>
        </div>
      </div> -->
      <div class="data">
        <div class="b_title">设备防护</div>
        <div class="item_list" v-if="xtfh.length > 0">
          <div class="item">
            <div class="name">{{ xtfh[9].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="xtfh[9].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              个
            </div>
          </div>
          <div class="item">
            <div class="name">{{ xtfh[4].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="xtfh[4].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              个
            </div>
          </div>
          <div class="item">
            <div class="name">{{ xtfh[8].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="xtfh[8].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              次
            </div>
          </div>
          <div class="item">
            <div class="name">{{ xtfh[5].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="xtfh[5].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              次
            </div>
          </div>
          <div class="item">
            <div class="name">负面数据占比</div>
            <div class="percent">
              <animate-number
                from="0"
                :to="xtfh[6].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              %
            </div>
          </div>
          <div class="item">
            <div class="name">{{ xtfh[7].tip }}</div>
            <div class="percent">
              <animate-number
                from="0"
                to="56"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
              次
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: [],
      form2: [],
      sjfh: [],
      xtfh: [],
      zdfh: [],
      zdsbq: [],
      zjfh: [],
      config2: {
        header: [
          '时间',
          '攻击源',
          '攻击者所在地',
          '攻击源捕获',
          '造成威胁',
          '措施',
        ],
        data: [],
        rowNum: 4, //表格行数
        headerHeight: 40,
        headerBGC: '#0F263D', //表头
        oddRowBGC: '#0F263D', //奇数行
        evenRowBGC: '#0F263D', //偶数行
        columnWidth: [100, 100, 110],
        align: ['center'],
      },
      config1: {
        header: [
          '时间',
          '攻击源',
          '攻击目标',
          '攻击源位置',
          '攻击方式',
          '外置措施',
        ],
        data: [],
        rowNum: 4, //表格行数
        headerHeight: 40,
        headerBGC: '#0F263D', //表头
        oddRowBGC: '#0F263D', //奇数行
        evenRowBGC: '#0F263D', //偶数行
        align: ['center'],
      },
    }
  },

  filters: {
    getcolor(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '#37C58F'
          break
        case 2:
          text = '#FFCB74'
          break
        case 3:
          text = '#FC3737'
          break
      }
      return text
    },
  },

  created() {
    this.getsafetyProtection()
  },

  methods: {
    open(url) {
      window.open(url)
    },
    async getsafetyProtection() {
      const {
        form,
        form2,
        list: { sjfh, xtfh, zdfh, zdsbq, zjfh },
      } = await this.$http.get('/index/safetyProtection')
      this.form = form
      this.form2 = form2
      this.sjfh = sjfh
      this.xtfh = xtfh
      this.zdfh = zdfh
      this.zdsbq = zdsbq
      this.zjfh = zjfh
      this.getconfig1()
      this.getconfig2()
    },

    getconfig2() {
      let arr = this.form2.map((item) => {
        return [
          item.value,
          `<span style="color:#FFCB74;" title='${item.value1}'>${item.value1}</span>`,
          `<span  title = "${item.value2}">${item.value2}</span>`,
          `${item.value3}`,
          `<span style="color:#FFCB74;">${item.value4}</span>`,
          `<span style="color:${this.$options.filters['getcolor'](
            item.status
          )};"">${item.value5}</span> `,
        ]
      })
      this.config2 = Object.assign({}, this.config2, { data: arr })
    },

    getconfig1() {
      let arr = this.form.map((item) => {
        return [
          item.value,
          `<span style="color:#FFCB74;" title='${item.value1}'>${item.value1}</span>`,
          `<span  title = "${item.value2}">${item.value2}</span>`,
          `${item.value3}`,
          `<span style="color:#FFCB74;">${item.value4}</span>`,
          `<span style="color:${this.$options.filters['getcolor'](
            item.status
          )};"">${item.value5}</span> `,
        ]
      })
      this.config1 = Object.assign({}, this.config1, { data: arr })
    },
  },
}
</script>

<style lang="scss" scoped>
#safetyProtection {
  padding: 0 20px;
  display: flex;
  .b_title {
    position: absolute;
    top: -11px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
  }
  .left {
    .item_list {
      padding: 40px 15px 0 15px;
      .item {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .name {
          font-size: 14px;
          color: #ffffff;
          line-height: 14px;
        }
        .percent {
          width: 90px;
          height: 28px;
          background: rgba(34, 108, 152, 0.28);
          box-shadow: inset 0px 0px 6px 0px #0082f3;
          opacity: 0.86;
          border: 1px solid #0b808c;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
          line-height: 16px;
        }
      }
    }
    .host {
      width: 234px;
      height: 253px;
      background: url('@ass/img/1.0.0/icon_ycaqfh_nrbg6.svg') no-repeat;
      background-size: 100%;
      position: relative;
    }

    .host2 {
      margin-bottom: 20px;
      width: 234px;
      height: 529px;
      background: url('@ass/img/1.0.0/icon_ycaqfh_nrbg1.svg') no-repeat;
      background-size: 100%;
      position: relative;
    }

    .data {
      width: 234px;
      height: 529px;
      background: url('@ass/img/1.0.0/icon_ycaqfh_nrbg3.svg') no-repeat;
      background-size: 100%;
      position: relative;
    }
  }
  .center {
    margin: 0 20px;
    .top {
      width: 1373px;
      height: 579px;
      background: url('@ass/img/1.0.0/icon_ycaqfh_ztt.svg');
      .number_contain {
        float: right;
        margin-right: 20px;
        margin-top: 60px;
        .item {
          width: 280px;
          padding: 15px 0 0 40px;
          .date_title {
            font-size: 14px;
            margin-bottom: 10px;
            .left_name {
              color: #ffffff;
            }
            .right_name {
              color: #bababa;
            }
          }
          .number {
            display: flex;
            align-items: flex-end;
            font-family: 'num';
            font-size: 26px;
            font-weight: bold;
            line-height: 26px;
            .left_number {
              color: #fed01c;
            }
            .right_number {
              font-size: 14px;
              color: #ffffff;
              line-height: 14px;
              margin-left: 5px;
            }
          }
        }
        .type1 {
          height: 79px;
          background: url('@ass/img/1.0.0/img_sy_sjbg01.png') no-repeat;
        }
      }
      .top_list {
        display: flex;
        align-items: center;
        margin-left: 191px;
        .top_item {
          margin-right: 45px;
          width: 160px;
          height: 36px;
          background: rgba(34, 108, 152, 0.28);
          box-shadow: inset 0px 0px 6px 0px #0082f3;
          border-radius: 2px;
          opacity: 0.86;
          border: 1px solid #0b808c;
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
          line-height: 36px;
          text-align: center;
        }
      }
    }
    .bottom {
      display: flex;
      margin-top: 20px;
      .bottom_item {
        padding-top: 20px;
        box-sizing: border-box;
        position: relative;
        width: 676px;
        height: 296px;
        background: url('@ass/img/1.0.0/icon_ycaqfh_nrbg4.svg') no-repeat;
        .body-box {
          height: 100%;
        }
        .dv-scroll-board {
          width: 95% !important;
          margin: 20px 10px;
          height: 81% !important;
          .row-item {
            height: 52px;
          }
        }
      }
    }
  }
}
</style>
