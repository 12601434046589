<template>
  <div class="absolute earthModel">
    <div ref="statsoutput"></div>
    <canvas ref="earthModel" class="earthCanvas"></canvas>
  </div>
</template>

<script>
import * as Three from 'three'
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
// import Stats from 'stats-js'

export default {
  name: 'earthModel',

  props: {
    //控制x轴波浪的长度
    amountX: {
      type: Number,
      default: 50,
    }, //控制y轴波浪的长度
    amountY: {
      type: Number,
      default: 50,
    }, //控制点颜色
    color: {
      type: String,
      default: '#097bdb',
    },
  },

  data() {
    return {
      group: [],
      group2: [],
      particles: [],
      count: 0,
      scene: null,
      camera: null,
      controls: null,
      webGLRenderer: null,
    }
  },

  mounted() {
    this.init()
  },

  destroyed() {
    cancelAnimationFrame(this.animationFrame)
  },

  methods: {
    //初始化统计对象
    // initStats() {
    //   this.stats = new Stats()
    //   //设置统计模式
    //   this.stats.setMode(0) // 0: fps, 1: ms
    //   //统计信息显示在左上角
    //   this.stats.domElement.style.position = 'absolute'
    //   this.stats.domElement.style.left = '0px'
    //   this.stats.domElement.style.top = '0px'
    //   //将统计对象添加到对应的<div>元素中
    //   this.$refs.statsoutput.appendChild(this.stats.domElement)
    //   return this.stats
    // },

    init() {
      //显示右上角fps框
      // this.initStats()

      this.group = new Three.Group()
      this.group2 = new Three.Group()
      this.scene = new Three.Scene()
      this.scene.add(this.group)
      this.scene.add(this.group2)
      this.group.rotation.y = -Math.PI

      this.webGLRenderer = new Three.WebGLRenderer({
        antialias: !0,
        alpha: !0,
        canvas: this.$refs.earthModel,
      })
      this.webGLRenderer.setPixelRatio(window.devicePixelRatio)
      // this.webGLRenderer.setSize(600, 474)
      this.webGLRenderer.setSize(1300, 700)

      // this.camera = new Three.PerspectiveCamera(40, 1.28, 1, 1e3)
      this.camera = new Three.PerspectiveCamera(
        60, //摄像机视锥体垂直视野角度
        1300 / 700, //摄像机视锥体长宽比
        1, //摄像机视锥体近端面
        10000 //摄像机视锥体远端面
      )
      this.group2.position.set(0, -1000, -4000) // 4000
      this.camera.position.set(0, 0, 60) // 60
      this.scene.add(this.camera)
      // console.log(Three, this.group, this.scene, this.camera)
      // const controls = new OrbitControls(this.camera, this.$refs.earthModel)
      // controls.rotateSpeed = 1
      // controls.enabled = !0
      // controls.noPan = !0
      // controls.noZoom = !0
      this.textureLoader(
        'https://f.dingdingkaike.com.cn/networksecurity/97ab87cd.png'
        // 'https://main.qcloudimg.com/raw/5710d029a80102ed6582f6608a72d28d.png'
      ).then((e) => {
        this.draw(e, this.group)
      })
    },

    textureLoader(e) {
      return new Promise(function (t) {
        new Three.TextureLoader().load(e, function (e) {
          t(e)
        })
      })
    },

    draw(e, t) {
      var n = new Three.MeshBasicMaterial({ map: e }),
        a = new Three.SphereBufferGeometry(17, 68, 68),
        r = new Three.Mesh(a, n)
      t.add(r)

      this.init2()

      this.animationFrame = requestAnimationFrame(this.render)
    },

    init2() {
      const SEPARATION = 130

      const numParticles = this.amountX * this.amountY

      const positions = new Float32Array(numParticles * 3)
      const scales = new Float32Array(numParticles)

      let i = 0,
        j = 0

      // 初始化粒子位置和大小
      for (let ix = 0; ix < this.amountX; ix++) {
        for (let iy = 0; iy < this.amountY; iy++) {
          positions[i] = ix * SEPARATION - (this.amountX * SEPARATION) / 2 // x
          positions[i + 1] = 0 // y
          positions[i + 2] = iy * SEPARATION - (this.amountY * SEPARATION) / 2 // z
          scales[j] = 1
          i += 3
          j++
        }
      }

      //是面片、线或点几何体的有效表述。包括顶点位置，面片索引、法相量、颜色值、UV 坐标和自定义缓存属性值。使用 BufferGeometry 可以有效减少向 GPU 传输上述数据所需的开销
      const geometry = new Three.BufferGeometry()
      geometry.setAttribute('position', new Three.BufferAttribute(positions, 3))
      geometry.setAttribute('scale', new Three.BufferAttribute(scales, 1))

      //着色器材质(ShaderMaterial),设置球的大小，颜色，等
      const material = new Three.ShaderMaterial({
        uniforms: {
          //设置球的颜色
          color: { value: new Three.Color(this.color) },
        },
        //控制球的大小
        vertexShader:
          'attribute float scale; void main() {vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );gl_PointSize = scale * ( 300.0 / - mvPosition.z );gl_Position = projectionMatrix * mvPosition;}',
        fragmentShader:
          'uniform vec3 color;void main() {if ( length( gl_PointCoord - vec2( 0.5, 0.5 ) ) > 0.475 ) discard;gl_FragColor = vec4( color, 1.0 );}',
      })

      //一个用于显示点的类。
      this.particles = new Three.Points(geometry, material)
      // console.log(this.group2)
      this.group2.add(this.particles)
    },

    // 粒子效果
    render2() {
      const positions = this.particles.geometry.attributes.position.array
      const scales = this.particles.geometry.attributes.scale.array // 设置粒子位置和大小
      let i = 0,
        j = 0
      for (let ix = 0; ix < this.amountX; ix++) {
        for (let iy = 0; iy < this.amountY; iy++) {
          positions[i + 1] =
            Math.sin((ix + this.count) * 0.3) * 50 +
            Math.sin((iy + this.count) * 0.5) * 50
          scales[j] =
            (Math.sin((ix + this.count) * 0.3) + 1) * 10 +
            (Math.sin((iy + this.count) * 0.5) + 1) * 10
          i += 3
          j++
        }
      }
      this.particles.geometry.attributes.position.needsUpdate = true
      this.particles.geometry.attributes.scale.needsUpdate = true
      this.webGLRenderer.render(this.scene, this.camera)
      this.count += 0.1
    },

    render() {
      this.group.rotation.y += 0.001
      this.group.rotation.x += 4e-4
      this.render2()
      this.animationFrame = requestAnimationFrame(this.render)
    },
  },
}
</script>

<style scoped lang="scss">
.earthModel {
  z-index: 0;
  top: -100px;
  box-sizing: border-box;
  .earthCanvas {
    margin: 0 auto;
    display: block;
  }
}
</style>
