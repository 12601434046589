<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-04-14 10:48:04
 * @LastEditTime: 2022-06-10 10:15:28
 * @LastEditors: cyy
 * @Description: 供电服务指挥
 * @FilePath: \networksecurity\src\views\marketingData\index.vue
-->

<template>
  <div id="powersupplyService">
    <div class="left">
      <div class="bottom_item bg3" v-if="zsjgk.length > 0">
        <div class="b_title">总数据概览</div>
        <div class="b_name">总库数</div>
        <div class="number _center" style="font-size: 50px">
          <animate-number
            from="0"
            :to="zsjgk[0].value"
            easing="easeOutQuad"
            style="display: block"
          ></animate-number>
        </div>
        <div class="datalist">
          <div class="d_item">
            <div class="tu">
              <img src="@ass/img/1.0.0/icon_yxsjtm_zbs.svg" alt="" />
            </div>
            <div class="name">总表数</div>
            <div class="number">
              <animate-number
                from="0"
                :to="zsjgk[1].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
            </div>
          </div>
          <div class="d_item">
            <div class="tu">
              <img src="@ass/img/1.0.0/icon_yxsjtm_zzds.svg" alt="" />
            </div>
            <div class="name">总字段数</div>
            <div class="number">
              <animate-number
                from="0"
                :to="zsjgk[2].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom_item bg3" v-if="mgsjgl.length > 0">
        <div class="b_title">敏感数据概览</div>
        <div class="b_name">敏感库数</div>
        <div class="number _center" style="font-size: 50px">
          <animate-number
            from="0"
            :to="mgsjgl[0].value"
            easing="easeOutQuad"
            style="display: block"
          ></animate-number>
        </div>
        <div class="datalist">
          <div class="d_item">
            <div class="tu">
              <img src="@ass/img/1.0.0/icon_yxsjtm_zbs.svg" alt="" />
            </div>
            <div class="name">敏感表数</div>
            <div class="number">
              <animate-number
                from="0"
                :to="mgsjgl[1].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
            </div>
          </div>
          <div class="d_item">
            <div class="tu">
              <img src="@ass/img/1.0.0/icon_yxsjtm_zzds.svg" alt="" />
            </div>
            <div class="name">敏感字段数</div>
            <div class="number">
              <animate-number
                from="0"
                :to="mgsjgl[2].value"
                easing="easeOutQuad"
                style="display: block"
              ></animate-number>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom_item bg3">
        <div class="b_title">知识库分布</div>
        <pie
          :acquiredOption="configure"
          style="width: 80%; margin: 0 auto"
          height="260px"
        ></pie>
      </div>
    </div>
    <div
      class="center cp"
      @click="open('http://10.160.180.15:28088/Portal')"
    ></div>
    <div class="right">
      <div class="bottom_item bg2">
        <div class="b_title">防火墙申请情况</div>
        <dataCrosseomain :form="form"></dataCrosseomain>
      </div>
    </div>
  </div>
</template>

<script>
import pie from '@cm/charts/pie'
import dataCrosseomain from './components/dataCrosseomain.vue'

export default {
  name: 'marketingData',

  components: {
    pie,
    dataCrosseomain,
  },

  data() {
    return {
      form: [],
      mgsjgl: [],
      zsjgk: [],
      zskfb: [],
      defect: {},
      configure: {},
    }
  },

  created() {
    this.getmarketingData()
  },

  watch: {
    zskfb: {
      handler(val) {
        if (val.length > 0) {
          this.getconfigure()
        }
      },
      immediate: true,
    },
  },

  methods: {
    open(url) {
      window.open(url)
    },
    async getmarketingData() {
      const {
        form,
        list: { mgsjgl, zsjgk, zskfb },
      } = await this.$http.get('/index/marketingData')
      this.form = form
      this.mgsjgl = mgsjgl
      this.zsjgk = zsjgk
      this.zskfb = zskfb
    },
    getconfigure() {
      let data1 = this.zskfb.map((item) => {
        let color =
          item.name == 'classic_case'
            ? '#44C8EE'
            : item.name == 'laws_regulations'
            ? '#ED576E'
            : item.name == 'central_requirements'
            ? '#DECA04'
            : '#5774FA'
        return {
          value: item.value,
          name: item.tip,
          itemStyle: {
            color,
          },
        }
      })

      this.configure = {
        legend: {
          bottom: '0%',
          itemWidth: 12,
          itemHeight: 12,
          textStyle: {
            color: '#fff',
          },
          itemGap: 16,
        },

        series: [
          {
            width: '128',
            type: 'pie',
            left: 'center',
            bottom: '20px',
            radius: ['60%', '80%'],
            avoidLabelOverlap: false,

            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold',
              },
            },

            label: {
              show: false,
            },

            data: data1,
          },
        ],
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#powersupplyService {
  display: flex;
  padding: 0 20px;
  .m-auto {
    margin: 0 auto;
  }
  img {
    height: 100%;
    width: auto;
  }
  .ml55mr123 {
    margin: 0 123px 0 55px;
  }
  .mlmr60 {
    margin: 0 60px;
  }
  .mb40 {
    margin-bottom: 40px;
  }
  .mt36 {
    margin-top: 36px;
  }
  .left {
    .datalist {
      margin-bottom: 28px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .d_item {
        display: flex;
        flex-direction: column;
        align-items: center;

        .tu {
          height: 40px;
        }
        .name {
          margin: 10px 0 14px;
          font-size: 14px;
          color: #ffffff;
          line-height: 14px;
        }
      }
    }
    .dv-scroll-board {
      width: 90%;
      margin: 30px 10px;
    }
    .b_title {
      position: absolute;
      top: -11px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
    }
    .b_name {
      padding-top: 60px;
      font-size: 14px;
      text-align: center;
      margin-bottom: 10px;
      color: #ffffff;
      line-height: 14px;
    }
    .bottom_item {
      position: relative;
      margin-bottom: 20px;
    }
    .bg3 {
      width: 285px;
      height: 288px;
      background: url('@ass/img/1.0.0/icon_yxsjtm_nrbg2.svg') no-repeat;
    }
  }
  .number {
    font-family: 'num';
    font-size: 14px;
    color: #fff;
    display: flex;
    justify-content: center;
  }

  .b_title {
    position: absolute;
    top: -11px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
  }
  .center {
    flex: 1;
    background: url('@ass/img/1.0.0/img_yxsjtmztt.png') no-repeat;
    background-position: top center;
  }
  .right {
    position: relative;
    .bg2 {
      width: 285px;
      height: 902px;
      background: url('@ass/img/1.0.0/icon_yxsjtm_nrbg4.svg') no-repeat;
    }
  }
}
</style>
